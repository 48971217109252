import { Component, Mixins, Watch } from "vue-property-decorator";
import AnalysisBase from "@/models/analysis/analysis-base";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import Flash, { ErrorAlert } from "@/store/common/flash";
import ShopGet from "@/store/shop/get";
import PointChart from "@/views/analysis/PointChart.vue";
import PointBalanceChart from "@/views/analysis/PointBalanceChart.vue";
import PointGet from "@/store/analysis/point/get";
import Admin from "@/store/admin/admin";
import { PointGetRequest } from "@/api/analysis/point/request";
import { PointBalanceGetRequest } from "@/api/analysis/point-balance/request";
import PointBalanceGet from "@/store/analysis/point-balance/get";
import { PointCompleteGetRequest } from "@/api/analysis/point-complete/request";
import PointCompleteGet from "@/store/analysis/point-complete/get";
import PointCompleteChart from "@/views/analysis/PointCompleteChart.vue";

export interface PointInputOptions {
  startDate: string;
  endDate: string;
  viewType: number;
  categoryType: number;
  selectShop: { id: string; name: string } | null;
}

@Component({
  components: {
    UlContentHeader,
    UIDatePicker,
    PointChart,
    PointBalanceChart,
    PointCompleteChart
  }
})
export default class AnalysisPoint extends Mixins(AnalysisBase) {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "ポイント";
  headingSub = "Point";
  breadCrumbs = [{ text: "ポイント", disabled: true }];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  viewTypes = [
    { label: "日別", value: 1 },
    { label: "月別", value: 2 },
    { label: "年別", value: 3 },
    { label: "時間別", value: 4 },
    { label: "曜日別", value: 5 }
  ];

  tabItems = [
    { title: "ポイント付与数" },
    { title: "利用金額" },
    { title: "ポイント残高別人数" },
    { title: "コンプリートチケット枚数" }
  ];

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoadedShopData = false;
  isLoadedPointData = false;
  isLoadedPointBalanceData = false;
  isLoadedPointCompleteData = false;

  // DatePickerの表示フラグ
  startDateModal = null;
  endDateModal = null;

  // 検索入力オプション
  pointInputOptions: PointInputOptions = {
    startDate: this.initDates[0],
    endDate: this.initDates[1],
    viewType: 1,
    categoryType: 1,
    selectShop: null
  };

  totalCountOnDrillDowned = 0;

  @Watch("activeTab")
  async watchActiveTab(newTabIndex: number) {
    this.totalCountOnDrillDowned = 0;
    if (newTabIndex < 2) {
      this.pointInputOptions.categoryType = newTabIndex + 1;
    }
    await this.fetchChartData();
  }

  /**
   * 総件数
   */
  get totalCount() {
    if (this.totalCountOnDrillDowned > 0) {
      return this.totalCountOnDrillDowned;
    } else {
      if (this.activeTab == 2) {
        return PointBalanceGet.getTotalCount;
      } else if (this.activeTab == 3) {
        return PointCompleteGet.getTotalCount;
      } else {
        return PointGet.getTotalCount;
      }
    }
  }

  get totalCountSuffix() {
    const suffixes = ["ポイント", "円", "人", "枚"];
    return suffixes[this.activeTab];
  }

  get pointData() {
    return PointGet.getData;
  }

  get balanceData() {
    return PointBalanceGet.getData;
  }

  get completeData() {
    return PointCompleteGet.getData;
  }

  get startDateForView() {
    return this.replaceHyphenToSlash(this.pointInputOptions.startDate);
  }
  set startDateForView(date) {
    this.pointInputOptions.startDate = this.replaceSlashToHyphen(date);
  }

  get endDateForView() {
    return this.replaceHyphenToSlash(this.pointInputOptions.endDate);
  }
  set endDateForView(date) {
    this.pointInputOptions.endDate = this.replaceSlashToHyphen(date);
  }

  get categoryType() {
    return this.activeTab;
  }

  get activeTab() {
    return this.$route.query.apt_tab ? Number(this.$route.query.apt_tab) : 0;
  }

  set activeTab(val) {
    let query = { ...this.$route.query };
    query.apt_tab = String(val);
    this.$router.replace({ query: query }).then();
  }

  get shopItems() {
    return ShopGet.getItems;
  }

  get initDates() {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDate = new Date(y, m, 1);
    const lastDate = new Date(y, m + 1, 0);
    return [this.stringFromDate(firstDate), this.stringFromDate(lastDate)];
  }

  get yesterday() {
    const date = new Date();
    return this.stringFromDate(
      new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)
    );
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    if (this.editFocused || !this.isNeededActiveOnDashBoard) {
      return false;
    }

    await this.fetchShopList();
/*    this.watchActiveTab(this.activeTab); */
    if (!Admin.isAdmin) {
      this.watchActiveTab(this.activeTab);
      this.pointInputOptions.selectShop = ShopGet.getItems[0];
      this.fetchChartData();
    }
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ShopGet.clearResponse();
    await PointGet.clearResponse();
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    this.isLoadedShopData = false;
    await ShopGet.get();
    if (!ShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedShopData = true;
    return ShopGet.isSuccess;
  }

  /**
   * グラフデータを取得する処理
   */
  async fetchChartData() {
    if (this.activeTab < 2) {
      await this.fetchPoint();
    } else if (this.activeTab == 2) {
      await this.fetchPointBalance();
    } else if (this.activeTab == 3) {
      await this.fetchPointComplete();
    }
  }

  async fetchPoint(): Promise<boolean> {
    this.isLoadedPointData = false;
    await Flash.clear();
    const pointGetRequest: PointGetRequest = {
      startDate: this.pointInputOptions.startDate,
      endDate: this.pointInputOptions.endDate,
      viewType: this.pointInputOptions.viewType,
      categoryType: this.pointInputOptions.categoryType,
      searchShopId:
        this.pointInputOptions.selectShop != null
          ? Number(this.pointInputOptions.selectShop.id)
          : null
    };
    await PointGet.get(pointGetRequest);
    if (!PointGet.isSuccess) {
      await Flash.setErrorNow({
        message: PointGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedPointData = true;
    return PointGet.isSuccess;
  }

  async fetchPointBalance(): Promise<boolean> {
    this.isLoadedPointBalanceData = false;
    await Flash.clear();

    const pointBalanceGetRequest: PointBalanceGetRequest = {
      date: this.yesterday,
      searchShopId:
        this.pointInputOptions.selectShop != null
          ? Number(this.pointInputOptions.selectShop.id)
          : null
    };

    await PointBalanceGet.get(pointBalanceGetRequest);
    if (!PointBalanceGet.isSuccess) {
      await Flash.setErrorNow({
        message: PointBalanceGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedPointBalanceData = true;
    return PointBalanceGet.isSuccess;
  }

  async fetchPointComplete(): Promise<boolean> {
    this.isLoadedPointCompleteData = false;
    await Flash.clear();

    const pointCompleteGetRequest: PointCompleteGetRequest = {
      date: this.yesterday,
      searchShopId:
        this.pointInputOptions.selectShop != null
          ? Number(this.pointInputOptions.selectShop.id)
          : null
    };

    await PointCompleteGet.get(pointCompleteGetRequest);
    if (!PointCompleteGet.isSuccess) {
      await Flash.setErrorNow({
        message: PointCompleteGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedPointCompleteData = true;
    return PointCompleteGet.isSuccess;
  }

  updateTotalCountOnDrillDowned(value: number) {
    this.totalCountOnDrillDowned = value;
  }
}
