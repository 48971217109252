import { Component, Mixins, Prop } from "vue-property-decorator";
import UlBarChart from "@/models/analysis/ul-bar-chart";
import AnalysisBase, { ChartElement } from "@/models/analysis/analysis-base";
import { AnalysisGraphData } from "@/api/analysis/common/response";
import RankPointGet from "@/store/analysis/rank-point/get";
import * as RankPointUserCSV from "@/api/analysis/rank-point-user-csv";
import { isSuccess } from "@/api/response";
import Flash, { ErrorAlert } from "@/store/common/flash";
import { RankPointInputOptions } from "@/models/analysis/rank-point";
import { RankPointUserCSVGetRequest } from "@/api/analysis/rank-point-user-csv/request";

@Component({
  components: {
    UlBarChart: UlBarChart
  }
})
export default class AnalysisRankPointChart extends Mixins(AnalysisBase) {
  @Prop() data!: AnalysisGraphData;
  @Prop() inputOptions!: RankPointInputOptions;

  get rankPointChartData() {
    return this.getBarChartData(this.data);
  }

  get rankPointChartOption() {
    return this.getBarChartOptions(this.data, false, false, this.onClickChart);
  }

  /**
   * createdライフサイクルフック
   */
  async created() {}

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {}

  async onClickChart(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0)
    ) {
      return;
    }
    if (event.ctrlKey || event.shiftKey) {
      const element = activeElements[0] as ChartElement;
      await this.fetchRankPointUserCSV(element._index);
    }
  }

  async fetchRankPointUserCSV(elementIndex: number) {
    const rankPointUserCSVGetRequest: RankPointUserCSVGetRequest = {
      searchShopId: this.inputOptions.selectShop
        ? Number(this.inputOptions.selectShop.id)
        : null,
      date: this.inputOptions.date,
      rank: Number(RankPointGet.getData.labelsData[elementIndex])
    };

    const rankPointUserCSVGetResponse = await RankPointUserCSV.get(
      rankPointUserCSVGetRequest
    );
    if (
      rankPointUserCSVGetResponse && 
      rankPointUserCSVGetResponse.statusCd !== 200
      ) {
        await Flash.setErrorNow({
          message: rankPointUserCSVGetResponse.message,
          showReloadButton: false
        } as ErrorAlert);
        return false;
    }

    this.saveCSV(
      rankPointUserCSVGetResponse.results.csvString,
      rankPointUserCSVGetResponse.results.fileName
    );
  }
}
