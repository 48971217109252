import service from "@/api/service";
import { IconTapListGetRequest } from "@/api/analysis/icon-tap/request";
import { DownloadCSVResponse } from "@/api/response";

/**
 * アイコンタップ数表CSV出力APIをコールします。
 *
 * @param getRequest アイコンタップ数表CSV出力APIのリクエストボディ
 * @return response
 */
export async function get(getRequest: IconTapListGetRequest) {
  const response = await service.postReceiveCSV(
    "/analysis-icon-tap-list-csv",
    getRequest
  );
  return response as DownloadCSVResponse;
}
