import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as PointBalanceAPI from "@/api/analysis/point-balance";
import { isSuccess } from "@/api/response";
import { PointBalanceGetRequest } from "@/api/analysis/point-balance/request";
import { PointBalanceGetResponse } from "@/api/analysis/point-balance/response";
import { AnalysisGraphData } from "@/api/analysis/common/response";

const MODULE_NAME = "analysis/point-balance/get";

/**
 *  ポイント残高別人数取得API（/analysis-point-balance）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: PointBalanceGetRequest = {} as PointBalanceGetRequest;
  getResponse: PointBalanceGetResponse = {} as PointBalanceGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getData() {
    if (this.getResponse.results) {
      return this.getResponse.results.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getBasePoint() {
    if (this.getResponse.results) {
      return this.getResponse.results.basePoint;
    } else {
      return 0;
    }
  }

  get getTotalCount() {
    if (this.getResponse.results) {
      return this.getResponse.results.totalCount;
    } else {
      return 0;
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }
  // MutationActions
  @MutationAction
  async get(getRequest: PointBalanceGetRequest) {
    const getResponse = await PointBalanceAPI.get(getRequest);
    return {
      getResponse,
      getRequest
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as PointBalanceGetResponse
    };
  }
}

export default getModule(Get);
