import service from "@/api/service";
import { CouponListGetRequest } from "@/api/analysis/coupon-list/request";
import { CouponListGetResponse } from "@/api/analysis/coupon-list/response";

/**
 * クーポン利用リスト取得APIをコールします。
 *
 * @param getRequest クーポン利用リスト取得APIのリクエストボディ
 * @return CouponListGetResponse
 */
export async function get(getRequest: CouponListGetRequest) {
  const response = await service.post("/analysis-coupon-list", getRequest);
  return response.data as CouponListGetResponse;
}
