import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as PointOriginalListAPI from "@/api/analysis/point-original-list";
import { isSuccess } from "@/api/response";
import { PointOriginalListGetRequest } from "@/api/analysis/point-original-list/request";
import { PointOriginalListGetResponse } from "@/api/analysis/point-original-list/response";

const MODULE_NAME = "analysis/point-original-list/get";

/**
 *  店舗ポイントカード利用数リスト取得API（/analysis-point-original-list）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: PointOriginalListGetRequest = {} as PointOriginalListGetRequest;
  getResponse: PointOriginalListGetResponse = {} as PointOriginalListGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getItems() {
    if (this.getResponse.results) {
      return this.getResponse.results.items;
    } else {
      return [];
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }
  // MutationActions
  @MutationAction
  async get(getRequest: PointOriginalListGetRequest) {
    const getResponse = await PointOriginalListAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as PointOriginalListGetResponse
    };
  }
}

export default getModule(Get);
