import { Component, Emit, Mixins, Prop, Watch } from "vue-property-decorator";
import UlBarChart from "@/models/analysis/ul-bar-chart";
import UlPieChart from "@/models/analysis/ul-pie-chart";
import AnalysisBase, { ChartElement } from "@/models/analysis/analysis-base";
import { AnalysisGraphData } from "@/api/analysis/common/response";
import Flash, { ErrorAlert } from "@/store/common/flash";
import VisitGet from "@/store/analysis/visit/get";
import VisitAttributeGet from "@/store/analysis/visit-attribute/get";
import { VisitInputOptions } from "@/models/analysis/visit";
import { VisitAttributeGetRequest } from "@/api/analysis/visit-attribute/request";
import { VisitAttributeGetResult } from "@/api/analysis/visit-attribute/response";
import { PointUserCSVGetRequest } from "@/api/analysis/point-user-csv/request";
import PointProfileGet from "@/store/analysis/point-profile/get";
import * as VisitUserCSV from "@/api/analysis/visit-user-csv";
import { isSuccess } from "@/api/response";
import { VisitUserCSVGetRequest } from "@/api/analysis/visit-user-csv/request";

@Component({
  components: {
    UlBarChart: UlBarChart,
    UlPieChart: UlPieChart
  }
})
export default class AnalysisVisitChart extends Mixins(AnalysisBase) {
  @Prop() inputOptions!: VisitInputOptions;
  @Prop() data!: AnalysisGraphData;
  @Prop() tabIndex!: number;

  @Watch("totalCountOnDrillDowned")
  didUpdateTotalCount(value: number) {
    this.updateTotalCountOnDrillDowned(value);
  }

  @Emit()
  public updateTotalCountOnDrillDowned(value: number) {
    return value;
  }

  chart: Record<string, any> = {};

  // ローディングステータス
  isLoadedVisitAttributeData = false;

  drillDownHierarchy = 0;
  totalCountOnDrillDowned = 0;

  // CSV出力API向けの日付
  dateForCsv = "";

  get visitChartData() {
    return this.getBarChartData(this.data);
  }

  get visitChartOptions() {
    return this.getBarChartOptions(
      this.data,
      false,
      false,
      this.drillDownVisitAttribute
    );
  }

  get customerClassChartData() {
    const result = VisitAttributeGet.getResult as VisitAttributeGetResult;
    const attributeData = result.customerClassData.data;
    return this.getPieChartData(attributeData);
  }

  get customerClassChartOptions() {
    const result = VisitAttributeGet.getResult as VisitAttributeGetResult;
    const attributeData = result.customerClassData.data;
    return this.getPieChartOptions(
      attributeData,
      this.fetchVisitUserCSVByCustomerClass
    );
  }

  get stampChartData() {
    const result = VisitAttributeGet.getResult as VisitAttributeGetResult;
    const attributeData = result.stampData.data;
    return this.getPieChartData(attributeData);
  }

  get stampChartOptions() {
    const result = VisitAttributeGet.getResult as VisitAttributeGetResult;
    const attributeData = result.stampData.data;
    return this.getPieChartOptions(
      attributeData,
      this.fetchVisitUserCSVByStamp
    );
  }

  get couponChartData() {
    const result = VisitAttributeGet.getResult as VisitAttributeGetResult;
    const attributeData = result.couponData.data;
    return this.getPieChartData(attributeData);
  }

  get couponChartOptions() {
    const result = VisitAttributeGet.getResult as VisitAttributeGetResult;
    const attributeData = result.couponData.data;
    return this.getPieChartOptions(
      attributeData,
      this.fetchVisitUserCSVByCoupon
    );
  }

  get pointChartData() {
    const result = VisitAttributeGet.getResult as VisitAttributeGetResult;
    const attributeData = result.pointData.data;
    return this.getPieChartData(attributeData);
  }

  get pointChartOptions() {
    const result = VisitAttributeGet.getResult as VisitAttributeGetResult;
    const attributeData = result.pointData.data;
    return this.getPieChartOptions(
      attributeData,
      this.fetchVisitUserCSVByPoint
    );
  }

  /**
   * createdライフサイクルフック
   */
  async created() {}

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {}

  async fetchVisitAttribute(
    inputOptions: VisitAttributeGetRequest
  ): Promise<boolean> {
    this.isLoadedVisitAttributeData = false;
    await Flash.clear();
    await VisitAttributeGet.get(inputOptions);
    if (!VisitAttributeGet.isSuccess) {
      await Flash.setErrorNow({
        message: VisitAttributeGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedVisitAttributeData = true;
    this.dateForCsv = VisitAttributeGet.getResult.date;
    return VisitAttributeGet.isSuccess;
  }

  onClickBack() {
    this.totalCountOnDrillDowned = 0;
    this.drillDownHierarchy = 0;
  }

  async drillDownVisitAttribute(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0) ||
      this.isDashboard ||
      this.inputOptions.selectShop == null
    ) {
      return;
    }

    if (event.ctrlKey || event.shiftKey) {
      const element = activeElements[0] as ChartElement;
      this.dateForCsv = this.data.labelsData[element._index];
      this.fetchVisitUserCSV(null, element._index);
      return;
    }

    const element = this.chart.getElementAtEvent(event)[0] as ChartElement;
    const visitAttributeGetRequest: VisitAttributeGetRequest = {
      shopId: Number(this.inputOptions.selectShop.id),
      viewType: this.inputOptions.viewType,
      date: this.data.labelsData[element._index]
    };

    await this.fetchVisitAttribute(visitAttributeGetRequest);

    const data = this.data.datasets[element._datasetIndex].data;
    if (data != null) {
      this.totalCountOnDrillDowned = Number(data[element._index]);
    }
    this.drillDownHierarchy = 1;
  }

  async fetchVisitUserCSVByCustomerClass(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchVisitUserCSVByAttribute(1, event, activeElements);
  }

  async fetchVisitUserCSVByStamp(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchVisitUserCSVByAttribute(2, event, activeElements);
  }

  async fetchVisitUserCSVByCoupon(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchVisitUserCSVByAttribute(3, event, activeElements);
  }

  async fetchVisitUserCSVByPoint(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchVisitUserCSVByAttribute(4, event, activeElements);
  }

  async fetchVisitUserCSVByAttribute(
    graphType: number,
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0)
    ) {
      return;
    }

    if (event.ctrlKey || event.shiftKey) {
      const element = activeElements[0] as ChartElement;
      await this.fetchVisitUserCSV(graphType, element._index);
    }
  }

  async fetchVisitUserCSV(graphType: number | null, elementIndex: number) {
    const visitUserCSVGetRequest: VisitUserCSVGetRequest = {
      shopId:
        this.inputOptions.selectShop != null
          ? Number(this.inputOptions.selectShop.id)
          : null,
      viewType: this.inputOptions.viewType,
      date: this.dateForCsv,
      totalCount: VisitGet.getTotalCount,
      graphType: graphType
    };

    if (graphType == 1) {
      visitUserCSVGetRequest.graphData =
        VisitAttributeGet.getResult.customerClassData.data.labelsData[
          elementIndex
        ];
    } else if (graphType == 2) {
      visitUserCSVGetRequest.graphData =
        VisitAttributeGet.getResult.stampData.data.labelsData[elementIndex];
    } else if (graphType == 3) {
      visitUserCSVGetRequest.graphData =
        VisitAttributeGet.getResult.couponData.data.labelsData[elementIndex];
    } else if (graphType == 4) {
      visitUserCSVGetRequest.graphData =
        VisitAttributeGet.getResult.pointData.data.labelsData[elementIndex];
    }

    const visitUserCSVGetResponse = await VisitUserCSV.get(
      visitUserCSVGetRequest
    );
    if (visitUserCSVGetResponse && visitUserCSVGetResponse.statusCd !== 200) {
      await Flash.setErrorNow({
        message: visitUserCSVGetResponse.message,
        showReloadButton: true
      } as ErrorAlert);
      return false;
    }

    this.saveCSV(
      visitUserCSVGetResponse.results.csvString,
      visitUserCSVGetResponse.results.fileName
    );
  }

  chartRendered(chart: Record<string, any>) {
    this.chart = chart;
  }
}
