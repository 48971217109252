import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as IconTapListAPI from "@/api/analysis/icon-tap-list";
import { isSuccess } from "@/api/response";
import { IconTapListGetRequest } from "@/api/analysis/icon-tap-list/request";
import { IconTapListGetResponse } from "@/api/analysis/icon-tap-list/response";

const MODULE_NAME = "analysis/icon-tap-list/get";

/**
 *  アイコンタップ数表取得API（/analysis-icon-tap-list）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: IconTapListGetRequest = {} as IconTapListGetRequest;
  getResponse: IconTapListGetResponse = {} as IconTapListGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getLabels() {
    if (this.getResponse.results) {
      return this.getResponse.results.labels;
    } else {
      return [] as string[];
    }
  }

  get getItems() {
    if (this.getResponse.results) {
      return this.getResponse.results.items;
    } else {
      return [] as object[];
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }
  // MutationActions
  @MutationAction
  async get(getRequest: IconTapListGetRequest) {
    const getResponse = await IconTapListAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as IconTapListGetResponse
    };
  }
}

export default getModule(Get);
