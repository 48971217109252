import {
  Component,
  Emit,
  Mixins,
  Prop,
  Vue,
  Watch
} from "vue-property-decorator";
import UlLineChart from "@/models/analysis/ul-line-chart";
import UlBarChart from "@/models/analysis/ul-bar-chart";
import AnalysisBase, { ChartElement } from "@/models/analysis/analysis-base";
import { StampGetData, TicketData } from "@/api/analysis/stamp/response";
import { AnalysisGraphData } from "@/api/analysis/common/response";
import { AttributeSelections } from "@/models/analysis/stamp";
import StampProfileGet from "@/store/analysis/stamp-profile/get";
import { StampProfileGetRequest } from "@/api/analysis/stamp-profile/request";
import Flash, { ErrorAlert } from "@/store/common/flash";
import StampCommonGet from "@/store/analysis/stamp-common/get";
import StampGet from "@/store/analysis/stamp/get";
import { StampUserCSVGetRequest } from "@/api/analysis/stamp-user-csv/request";
import * as StampUserCSV from "@/api/analysis/stamp-user-csv";
import { isSuccess } from "@/api/response";
import { ChartPoint } from "chart.js";

@Component({
  components: {
    UlLineChart,
    UlBarChart
  }
})
export default class AnalysisStampChart extends Mixins(AnalysisBase) {
  @Prop() commonData!: AnalysisGraphData;
  @Prop() grantData!: StampGetData;
  @Prop() ticketData!: TicketData;
  @Prop() cardData!: StampGetData;
  @Prop() mode!: number;

  @Watch("totalCountOnDrillDowned")
  didUpdateTotalCount(value: number) {
    this.updateTotalCountOnDrillDowned(value);
  }

  @Emit()
  public updateTotalCountOnDrillDowned(value: number) {
    return { totalCount: value, suffix: this.totalCountSuffix };
  }

  isLoadedStampProfile = false;
  drillDownHierarchy = 0;
  totalCountOnDrillDowned = 0;
  totalCountSuffix = "個";
  attributeSelections: AttributeSelections[] = [];
  showDialog = false;

  stampCommonChart: Record<string, any> = {};
  stampTicketLineChart: Record<string, any> = {};
  stampCardLineChart: Record<string, any> = {};

  isReFetched = false;

  get stampCommonChartData() {
    return this.getLineChartData(this.commonData);
  }

  get stampCommonChartOption() {
    return this.getLineChartOptions(
      this.commonData,
      true,
      this.drillDownStampProfileByCommonData
    );
  }

  get stampGrantChartData() {
    return this.getBarChartData(this.grantData.data);
  }

  get stampGrantChartOption() {
    return this.getBarChartOptions(
      this.grantData.data,
      false,
      false,
      this.drillDownStampProfileByStampDataFromGrantChart
    );
  }

  get stampTicketBarChartData() {
    return this.getBarChartData(this.ticketData.data);
  }

  get stampTicketBarChartOption() {
    return this.getBarChartOptions(
      this.ticketData.data,
      false,
      false,
      this.drillDownStampProfileByStampDataFromTicketBarChart
    );
  }

  get stampCardBarChartData() {
    return this.getBarChartData(this.cardData.data);
  }

  get stampCardBarChartOption() {
    return this.getBarChartOptions(
      this.cardData.data,
      false,
      false,
      this.drillDownStampProfileByStampDataFromCardBarChart
    );
  }

  get stampTicketLineChartData() {
    return this.getLineChartData(this.ticketData.data);
  }

  get stampTicketLineChartOption() {
    return this.getLineChartOptions(
      this.ticketData.data,
      true,
      this.drillDownStampProfileByStampDataFromTicketLineChart
    );
  }

  get stampCardLineChartData() {
    return this.getLineChartData(this.cardData.data);
  }

  get stampCardLineChartOption() {
    return this.getLineChartOptions(
      this.cardData.data,
      false,
      this.drillDownStampProfileByStampDataFromCardLineChart
    );
  }

  get stampRankingChartData() {
    return this.getBarChartData(StampProfileGet.getRankingData);
  }

  get stampRankingChartOption() {
    return this.getBarChartOptions(
      StampProfileGet.getRankingData,
      false,
      false,
      this.fetchStampUserCSVByRankingData
    );
  }

  get stampTopChartData() {
    return this.getBarChartData(StampProfileGet.getTopData);
  }

  get stampTopChartOption() {
    return this.getBarChartOptions(
      StampProfileGet.getTopData,
      false,
      false,
      this.fetchStampUserCSVByTopData
    );
  }

  get attributeSelectionsIsDisabled() {
    return this.attributeSelections.filter(a => a.isSelected).length > 1;
  }

  get attributeDoneIsDisabled() {
    return this.attributeSelections.filter(a => a.isSelected).length == 0;
  }

  /**
   * createdライフサイクルフック
   */
  async created() {}

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await StampProfileGet.clearResponse();
  }

  async fetchStampProfile(request: StampProfileGetRequest): Promise<boolean> {
    this.isLoadedStampProfile = false;
    await Flash.clear();
    await StampProfileGet.get(request);
    if (!StampProfileGet.isSuccess) {
      await Flash.setErrorNow({
        message: StampProfileGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedStampProfile = true;
    return StampProfileGet.isSuccess;
  }

  async drillDownStampProfileByCommonData(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0) ||
      this.isDashboard
    ) {
      return;
    }

    const element = this.stampCommonChart.getElementAtEvent(
      event
    )[0] as ChartElement;
    if (event.ctrlKey || event.shiftKey) {
      await this.fetchStampUserCSV(
        StampCommonGet.getGetRequest.searchShopId,
        StampCommonGet.getGetRequest.startDate,
        StampCommonGet.getGetRequest.endDate,
        StampCommonGet.getGetRequest.viewType,
        1,
        event,
        element._index,
        StampCommonGet.getData.labelsData[element._index]
      );
      return;
    }
    this.drillDownStampProfile(
      event,
      element,
      1,
      1,
      StampCommonGet.getData.labelsData[element._index]
    );
  }

  async drillDownStampProfileByStampDataFromTicketLineChart(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined
  ) {
    this.drillDownStampProfileByStampData(event, activeElements, 2);
  }

  async drillDownStampProfileByStampDataFromCardLineChart(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined
  ) {
    this.drillDownStampProfileByStampData(event, activeElements, 3);
  }

  async drillDownStampProfileByStampDataFromGrantChart(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined
  ) {
    this.drillDownStampProfileByStampData(event, activeElements, 4);
  }

  async drillDownStampProfileByStampDataFromTicketBarChart(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined
  ) {
    this.drillDownStampProfileByStampData(event, activeElements, 5);
  }

  async drillDownStampProfileByStampDataFromCardBarChart(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined
  ) {
    this.drillDownStampProfileByStampData(event, activeElements, 6);
  }

  async drillDownStampProfileByStampData(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined,
    graphType: number
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0)
    ) {
      return;
    }
    let element = activeElements[0] as ChartElement;

    let horizontalAxis = "";
    if (graphType == 1) {
      horizontalAxis = this.commonData.labelsData[element._index];
    } else if (graphType == 2) {
      element = this.stampTicketLineChart.getElementAtEvent(
        event
      )[0] as ChartElement;
      horizontalAxis = this.ticketData.data.labelsData[element._index];
    } else if (graphType == 3) {
      horizontalAxis = this.cardData.data.labelsData[element._index];
    } else if (graphType == 4) {
      horizontalAxis = this.grantData.data.labelsData[element._index];
    } else if (graphType == 5) {
      horizontalAxis = this.ticketData.data.labelsData[element._index];
    } else if (graphType == 6) {
      horizontalAxis = this.cardData.data.labelsData[element._index];
    }

    if (event.ctrlKey || event.shiftKey) {
      await this.fetchStampUserCSV(
        StampGet.getGetRequest.searchShopId,
        StampGet.getGetRequest.startDate,
        StampGet.getGetRequest.endDate,
        StampGet.getGetRequest.viewType,
        graphType,
        event,
        element._index,
        horizontalAxis
      );
      return;
    }

    this.drillDownStampProfile(
      event,
      element,
      graphType,
      graphType == 3 ? 3 : 2,
      horizontalAxis
    );
  }

  async drillDownStampProfile(
    event: MouseEvent,
    activeElement: ChartElement,
    graphType: number,
    drillDownHierarchy: number,
    horizontalAxis: string
  ) {
    const stampProfileGetRequest: StampProfileGetRequest = {
      shopId: StampCommonGet.getGetRequest.searchShopId,
      startDate: StampCommonGet.getGetRequest.startDate,
      endDate: StampCommonGet.getGetRequest.endDate,
      viewType: StampCommonGet.getGetRequest.viewType,
      graphType: graphType,
      horizontalAxis: "",
      attribute1: 1,
      attribute2: 2
    };

    if (this.isReFetched) {
      stampProfileGetRequest.attribute1 =
        StampProfileGet.getSelectedAttributes.length > 0
          ? StampProfileGet.getSelectedAttributes[0].value
          : 1;
      stampProfileGetRequest.attribute2 =
        StampProfileGet.getSelectedAttributes.length > 1
          ? StampProfileGet.getSelectedAttributes[1].value
          : null;
    }

    if (graphType == 2) {
      stampProfileGetRequest.ticketId = this.ticketData.data.datasets[
        activeElement._datasetIndex
      ].ticketId;
    }
    stampProfileGetRequest.horizontalAxis = horizontalAxis;

    await this.fetchStampProfile(stampProfileGetRequest);

    const attributeSelections: AttributeSelections[] = [];
    StampProfileGet.getLabelsAttribute.forEach((l, i) => {
      const isSelected =
        StampProfileGet.getSelectedAttributes.length == 0 && i <= 1;

      const attribute = {
        label: l,
        value: i + 1,
        isSelected: isSelected,
        isEnabled: l !== ""
      };

      const selectedAttribute = StampProfileGet.getSelectedAttributes.find(
        s => attribute.label == s.label
      );
      if (selectedAttribute) {
        attribute.isSelected = true;
      }
      attributeSelections.push(attribute);
    });
    this.attributeSelections = attributeSelections;

    let data:
      | Array<number | null | undefined | number[]>
      | ChartPoint[]
      | undefined = [];
    if (graphType == 1) {
      data = this.commonData.datasets[activeElement._datasetIndex].data;
      //NEW_DEV-2282 cyber start
      if (data[activeElement._index] == "0"){
        return;
      }
      //NEW_DEV-2282 cyber end
    } else if (graphType == 2 || graphType == 5) {
      data = this.ticketData.data.datasets[activeElement._datasetIndex].data;
      this.totalCountSuffix = "枚";
    } else if (graphType == 3 || graphType == 6) {
      data = this.cardData.data.datasets[activeElement._datasetIndex].data;
      this.totalCountSuffix = "枚";
    } else if (graphType == 4) {
      data = this.grantData.data.datasets[activeElement._datasetIndex].data;
    }
    if (data != null) {
      this.totalCountOnDrillDowned = Number(data[activeElement._index]);
    }

    this.drillDownHierarchy = drillDownHierarchy;
  }

  async reFetchStampProfile() {
    const stampProfileGetRequest: StampProfileGetRequest = {
      shopId: StampCommonGet.getGetRequest.searchShopId,
      startDate: StampCommonGet.getGetRequest.startDate,
      endDate: StampCommonGet.getGetRequest.endDate,
      viewType: StampCommonGet.getGetRequest.viewType,
      graphType: StampProfileGet.getGetRequest.graphType,
      horizontalAxis: StampProfileGet.getGetRequest.horizontalAxis,
      attribute1: null,
      attribute2: null
    };

    await StampProfileGet.clearResponse();

    const selectedAttributes = this.attributeSelections.filter(
      a => a.isSelected
    );
    await StampProfileGet.setSelectedAttributes(selectedAttributes);

    if (selectedAttributes.length > 0) {
      stampProfileGetRequest.attribute1 = selectedAttributes[0].value;
    }
    if (selectedAttributes.length > 1) {
      stampProfileGetRequest.attribute2 = selectedAttributes[1].value;
    }

    if (StampProfileGet.getGetRequest.graphType == 2) {
      stampProfileGetRequest.ticketId = StampProfileGet.getGetRequest.ticketId;
    }

    await this.fetchStampProfile(stampProfileGetRequest);
    this.showDialog = false;
    this.isReFetched = true;
  }

  onClickBack() {
    this.totalCountOnDrillDowned = 0;
    this.totalCountSuffix = "個";
    this.drillDownHierarchy = 0;
  }

  async fetchStampUserCSVByRankingData(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0)
    ) {
      return;
    }
    const element = activeElements[0] as ChartElement;
    await this.fetchStampUserCSV(
      StampProfileGet.getGetRequest.shopId,
      StampProfileGet.getGetRequest.startDate,
      StampProfileGet.getGetRequest.endDate,
      StampProfileGet.getGetRequest.viewType,
      7,
      event,
      element._index,
      StampProfileGet.getGetRequest.horizontalAxis
    );
  }

  async fetchStampUserCSVByTopData(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0)
    ) {
      return;
    }
    const element = activeElements[0] as ChartElement;
    await this.fetchStampUserCSV(
      StampProfileGet.getGetRequest.shopId,
      StampProfileGet.getGetRequest.startDate,
      StampProfileGet.getGetRequest.endDate,
      StampProfileGet.getGetRequest.viewType,
      8,
      event,
      element._index,
      StampProfileGet.getGetRequest.horizontalAxis
    );
  }

  async fetchStampUserCSV(
    shopId: number | null,
    startDate: string,
    endDate: string,
    viewType: number,
    graphType: number,
    event: MouseEvent,
    elementIndex: number,
    horizontalAxis: string
  ) {
    if (event.ctrlKey || event.shiftKey) {
      const stampUserCSVGetRequest = this.createStampUserCSVGetRequest(
        shopId,
        startDate,
        endDate,
        viewType,
        graphType,
        elementIndex,
        horizontalAxis
      );
      if (!stampUserCSVGetRequest) {
        return;
      }

      const stampUserCSVGetResponse = await StampUserCSV.get(
        stampUserCSVGetRequest
      );

      if (stampUserCSVGetResponse && stampUserCSVGetResponse.statusCd !== 200) {
        await Flash.setErrorNow({
          message: stampUserCSVGetResponse.message,
          showReloadButton: false
        } as ErrorAlert);
        return false;
      }

      this.saveCSV(
        stampUserCSVGetResponse.results.csvString,
        stampUserCSVGetResponse.results.fileName
      );
    }
  }

  private createStampUserCSVGetRequest(
    shopId: number | null,
    startDate: string,
    endDate: string,
    viewType: number,
    graphType: number,
    elementIndex: number,
    horizontalAxis: string
  ): StampUserCSVGetRequest | null {
    const stampUserCSVGetRequest: StampUserCSVGetRequest = {
      shopId: shopId,
      startDate: startDate,
      endDate: endDate,
      viewType: viewType,
      graphType: graphType,
      horizontalAxis: horizontalAxis,
      attribute1: null,
      attribute2: null,
      attribute1Data: null,
      attribute2Data: null
    };

    if (graphType === 2) {
      stampUserCSVGetRequest.ticketId = this.ticketData.data.datasets[0].ticketId;
    }

    if (graphType === 7 || graphType === 8) {
      stampUserCSVGetRequest.beforeGraphType =
        StampProfileGet.getGetRequest.graphType;

      if (stampUserCSVGetRequest.beforeGraphType === 2) {
        stampUserCSVGetRequest.ticketId = this.ticketData.data.datasets[0].ticketId;
      }

      if (graphType === 7) {
        if (StampProfileGet.getSelectedAttributes.length > 0) {
          stampUserCSVGetRequest.attribute1 =
            StampProfileGet.getSelectedAttributes[0].value;
          stampUserCSVGetRequest.attribute1Data =
            StampProfileGet.getRankingData.labelsData1[elementIndex];
        }
        if (StampProfileGet.getSelectedAttributes.length > 1) {
          stampUserCSVGetRequest.attribute2 =
            StampProfileGet.getSelectedAttributes[1].value;
          stampUserCSVGetRequest.attribute2Data =
            StampProfileGet.getRankingData.labelsData2[elementIndex];
        }
      }

      if (graphType === 8) {
        stampUserCSVGetRequest.topAttributeType =
          StampProfileGet.getTopData.labelsAttributeData[elementIndex];
        stampUserCSVGetRequest.topAttributeData =
          StampProfileGet.getTopData.labels[elementIndex];
      }
    }

    return stampUserCSVGetRequest;
  }

  resetAttributeSelections() {
    this.attributeSelections.map(a => {
      Vue.set(a, "isSelected", false);
    });
  }

  stampCommonChartRendered(chart: Record<string, any>) {
    this.stampCommonChart = chart;
  }

  stampTicketLineChartRendered(chart: Record<string, any>) {
    this.stampTicketLineChart = chart;
  }

  stampCardLineChartRendered(chart: Record<string, any>) {
    this.stampCardLineChart = chart;
  }
}
