import service from "@/api/service";
import { IconTapListGetRequest } from "@/api/analysis/icon-tap/request";
import { IconTapGetResponse } from "@/api/analysis/icon-tap/response";

/**
 * アイコンタップAPIをコールします。
 *
 * @param getRequest アイコンタップAPIのリクエストボディ
 * @return IconTapGetResponse
 */
export async function get(getRequest: IconTapListGetRequest) {
  const response = await service.post("/analysis-icon-tap", getRequest);
  return response.data as IconTapGetResponse;
}
