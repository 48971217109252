import service from "@/api/service";
import { AppDownloadUserCSVGetRequest } from "@/api/analysis/app-download-user-csv/request";
import { DownloadCSVResponse } from "@/api/response";

/**
 * アプリDLユーザーCSV出力APIをコールします。
 *
 * @param getRequest アプリDLユーザーCSV出力APIのリクエストボディ
 * @return response
 */
export async function get(getRequest: AppDownloadUserCSVGetRequest) {
  const response = await service.postReceiveCSV(
    "/analysis-app-download-user-csv",
    getRequest
  );
  return response as DownloadCSVResponse;
}
