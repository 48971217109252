import service from "@/api/service";
import { PointCompleteProfileGetRequest } from "@/api/analysis/point-complete-profile/request";
import { PointCompleteProfileGetResponse } from "@/api/analysis/point-complete-profile/response";

/**
 * ポイントコンプリートチケットプロフィール属性情報取得APIをコールします。
 *
 * @param getRequest ポイントコンプリートチケットプロフィール属性情報取得APIのリクエストボディ
 * @return PointCompleteProfileGetResponse
 */
export async function get(getRequest: PointCompleteProfileGetRequest) {
  const response = await service.post(
    "/analysis-point-complete-profile",
    getRequest
  );
  return response.data as PointCompleteProfileGetResponse;
}
