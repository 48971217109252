import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as AnalysisCouponProfileAPI from "@/api/analysis/coupon-profile";
import { isSuccess } from "@/api/response";
import { CouponProfileGetRequest } from "@/api/analysis/coupon-profile/request";
import { CouponProfileGetResponse } from "@/api/analysis/coupon-profile/response";
import { AnalysisGraphData } from "@/api/analysis/common/response";

const MODULE_NAME = "analysis/coupon-profile/get";

/**
 *  クーポンプロフィール属性API（/analysis-coupon-profile）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: CouponProfileGetRequest = {} as CouponProfileGetRequest;
  getResponse: CouponProfileGetResponse = {} as CouponProfileGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getGenderData() {
    if (this.getResponse.results && this.getResponse.results["genderData"]) {
      return this.getResponse.results.genderData.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getPeriodData() {
    if (this.getResponse.results && this.getResponse.results["periodData"]) {
      return this.getResponse.results.periodData.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getProfile1Data() {
    if (this.getResponse.results && this.getResponse.results["profile1Data"]) {
      return this.getResponse.results.profile1Data.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getProfile2Data() {
    if (this.getResponse.results && this.getResponse.results["profile2Data"]) {
      return this.getResponse.results.profile2Data.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getProfile3Data() {
    if (this.getResponse.results && this.getResponse.results["profile3Data"]) {
      return this.getResponse.results.profile3Data.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getProfile4Data() {
    if (this.getResponse.results && this.getResponse.results["profile4Data"]) {
      return this.getResponse.results.profile4Data.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getProfile5Data() {
    if (this.getResponse.results && this.getResponse.results["profile5Data"]) {
      return this.getResponse.results.profile5Data.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }

  // MutationActions
  @MutationAction
  async get(getRequest: CouponProfileGetRequest) {
    const getResponse = await AnalysisCouponProfileAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as CouponProfileGetResponse
    };
  }
}

export default getModule(Get);
