import service from "@/api/service";
import { PointCompleteUserCSVGetRequest } from "@/api/analysis/point-complete-user-csv/request";
import { DownloadCSVResponse } from "@/api/response";

/**
 * ポイントコンプリートチケットユーザーCSV出力APIをコールします。
 *
 * @param getRequest ポイントコンプリートチケットユーザーCSV出力APIのリクエストボディ
 * @return response
 */
export async function get(getRequest: PointCompleteUserCSVGetRequest) {
  const response = await service.postReceiveCSV(
    "/analysis-point-complete-user-csv",
    getRequest
  );
  return response as DownloadCSVResponse;
}
