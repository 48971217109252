import { Component, Mixins, Watch } from "vue-property-decorator";
import AnalysisBase from "@/models/analysis/analysis-base";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import Flash, { ErrorAlert } from "@/store/common/flash";
import ShopGet from "@/store/shop/get";
import AttributesStatisticsChart from "@/views/analysis/AttributeStatisticsChart.vue";
import AttributesStatisticsGet from "@/store/analysis/attributes-statistics/get";
import { AttributesStatisticsGetRequest } from "@/api/analysis/attributes-statistics/request";
import Admin from "@/store/admin/admin";

interface AttributesStatisticsInputOptions {
  startDate: string;
  endDate: string;
  categoryType: number;
  selectShop: { id: string; name: string } | null;
}

@Component({
  components: {
    UlContentHeader,
    UIDatePicker,
    AttributesStatisticsChart
  }
})
export default class AnalysisAttributesStatistics extends Mixins(AnalysisBase) {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "属性統計";
  headingSub = "Attributes statistics";
  breadCrumbs = [{ text: "属性統計", disabled: true }];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  tabItems = [
    { title: "ダウンロード数" },
    { title: "ポイント付与数" },
    { title: "クーポン利用数" }
  ];

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoadedShopData = false;
  isLoadedAttributesStatisticsData = false;

  // DatePickerの表示フラグ
  startDateModal = null;
  endDateModal = null;

  // 検索入力オプション
  inputOptions: AttributesStatisticsInputOptions = {
    startDate: this.initDates[0],
    endDate: this.initDates[1],
    categoryType: 1,
    selectShop: null
  };

  @Watch("activeTab")
  watchActiveTab(newTabIndex: number) {
    this.inputOptions.categoryType = newTabIndex + 1;
  }

  /**
   * 総件数
   */
  get totalCount() {
    return AttributesStatisticsGet.getTotalCount;
  }

  get attributesStatisticsData() {
    return AttributesStatisticsGet.getResult;
  }

  get startDateForView() {
    return this.replaceHyphenToSlash(this.inputOptions.startDate);
  }
  set startDateForView(date) {
    this.inputOptions.startDate = this.replaceSlashToHyphen(date);
  }

  get endDateForView() {
    return this.replaceHyphenToSlash(this.inputOptions.endDate);
  }
  set endDateForView(date) {
    this.inputOptions.endDate = this.replaceSlashToHyphen(date);
  }

  get categoryType() {
    return this.activeTab;
  }

  get activeTab() {
    return this.$route.query.aas_tab ? Number(this.$route.query.aas_tab) : 0;
  }

  set activeTab(val) {
    let query = { ...this.$route.query };
    query.aas_tab = String(val);
    this.$router.replace({ query: query }).then();
  }

  get shopItems() {
    return ShopGet.getItems;
  }

  get initDates() {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDate = new Date(y, m, 1);
    const lastDate = new Date(y, m + 1, 0);
    return [this.stringFromDate(firstDate), this.stringFromDate(lastDate)];
  }

  get totalCountSuffix() {
    const suffixes = ["DL", "Point", "回"];
    return suffixes[this.activeTab];
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    if (this.editFocused || !this.isNeededActiveOnDashBoard) {
      return false;
    }

    await this.fetchShopList();
    this.watchActiveTab(this.activeTab);
    if (!Admin.isAdmin) {
      this.inputOptions.selectShop = ShopGet.getItems[0];
      await this.fetchRootChartData();
    }
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ShopGet.clearResponse();
    await AttributesStatisticsGet.clearResponse();
  }

  async fetchRootChartData() {
    this.isLoadedAttributesStatisticsData = false;
    await this.fetchAttributesStatistics();
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    this.isLoadedShopData = false;
    await ShopGet.get();
    if (!ShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedShopData = true;
    return ShopGet.isSuccess;
  }

  /**
   * グラフデータを取得する処理
   */
  async fetchAttributesStatistics(): Promise<boolean> {
    this.isLoadedAttributesStatisticsData = false;
    await Flash.clear();
    const attributesStatisticsGetRequest: AttributesStatisticsGetRequest = {
      startDate: this.inputOptions.startDate,
      endDate: this.inputOptions.endDate,
      categoryType: this.inputOptions.categoryType,
      searchShopId:
        this.inputOptions.selectShop == null
          ? null
          : parseInt(this.inputOptions.selectShop.id)
    };
    await AttributesStatisticsGet.get(attributesStatisticsGetRequest);
    if (!AttributesStatisticsGet.isSuccess) {
      await Flash.setErrorNow({
        message: AttributesStatisticsGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedAttributesStatisticsData = true;
    return AttributesStatisticsGet.isSuccess;
  }
}
