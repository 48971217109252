import service from "@/api/service";
import { CouponUseCSVGetRequest } from "@/api/analysis/coupon-user-csv/request";
import { DownloadCSVResponse } from "@/api/response";

/**
 * クーポンユーザーCSV出力APIをコールします。
 *
 * @param getRequest クーポンユーザーCSV出力APIのリクエストボディ
 * @return response
 */
export async function get(getRequest: CouponUseCSVGetRequest) {
  const response = await service.postReceiveCSV(
    "/analysis-coupon-user-csv",
    getRequest
  );
  return response as DownloadCSVResponse;
}
