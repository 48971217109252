import service from "@/api/service";
import { StampGetResponse } from "@/api/analysis/stamp/response";
import { StampGetRequest } from "@/api/analysis/stamp/request";

/**
 * スタンプ取得APIをコールします。
 *
 * @param getRequest スタンプ取得APIのリクエストボディ
 * @return StampGetResponse
 */
export async function get(getRequest: StampGetRequest) {
  const response = await service.post("/analysis-stamp", getRequest);
  return response.data as StampGetResponse;
}
