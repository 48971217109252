import service from "@/api/service";
import { IconTapProfileGetRequest } from "@/api/analysis/icon-tap-profile/request";
import { IconTapProfileGetResponse } from "@/api/analysis/icon-tap-profile/response";

/**
 * アイコンタップ数プロフィール属性APIをコールします。
 *
 * @param getRequest アイコンタップ数プロフィール属性APIのリクエストボディ
 * @return IconTapProfileGetResponse
 */
export async function get(getRequest: IconTapProfileGetRequest) {
  const response = await service.post("/analysis-icon-tap-profile", getRequest);
  return response.data as IconTapProfileGetResponse;
}
