import { Component, Mixins, Watch } from "vue-property-decorator";
import AnalysisBase from "@/models/analysis/analysis-base";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import Flash, { ErrorAlert } from "@/store/common/flash";
import ShopGet from "@/store/shop/get";
import VisitGet from "@/store/analysis/visit/get";
import Admin from "@/store/admin/admin";
import VisitChart from "@/views/analysis/VisitChart.vue";
import { VisitGetRequest } from "@/api/analysis/visit/request";

export interface VisitInputOptions {
  startDate: string;
  endDate: string;
  viewType: number;
  selectShop: { id: string; name: string } | null;
}

@Component({
  components: {
    UlContentHeader,
    UIDatePicker,
    VisitChart
  }
})
export default class AnalysisVisit extends Mixins(AnalysisBase) {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "アプリユーザ来店数";
  headingSub = "Number of visitors";
  breadCrumbs = [{ text: "アプリユーザ来店数", disabled: true }];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  viewTypes = [
    { label: "日別", value: 1 },
    { label: "月別", value: 2 }
  ];

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoadedShopData = false;
  isLoadedVisitData = false;

  // DatePickerの表示フラグ
  startDateModal = null;
  endDateModal = null;

  totalCountOnDrillDowned = 0;

  // 検索入力オプション
  visitInputOptions: VisitInputOptions = {
    startDate: this.initDates[0],
    endDate: this.initDates[1],
    viewType: 1,
    selectShop: null
  };

  /**
   * 総件数
   */
  get totalCount() {
    return this.totalCountOnDrillDowned > 0
      ? this.totalCountOnDrillDowned
      : VisitGet.getTotalCount;
  }

  get visitData() {
    return VisitGet.getData;
  }

  get startDateForView() {
    return this.replaceHyphenToSlash(this.visitInputOptions.startDate);
  }
  set startDateForView(date) {
    this.visitInputOptions.startDate = this.replaceSlashToHyphen(date);
  }

  get endDateForView() {
    return this.replaceHyphenToSlash(this.visitInputOptions.endDate);
  }
  set endDateForView(date) {
    this.visitInputOptions.endDate = this.replaceSlashToHyphen(date);
  }

  get shopItems() {
    return ShopGet.getItems;
  }

  get initDates() {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDate = new Date(y, m, 1);
    const lastDate = new Date(y, m + 1, 0);
    return [this.stringFromDate(firstDate), this.stringFromDate(lastDate)];
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    if (this.editFocused || !this.isNeededActiveOnDashBoard) {
      return false;
    }

    await this.fetchShopList();
    if (!Admin.isAdmin) {
      this.visitInputOptions.selectShop = ShopGet.getItems[0];
      await this.fetchVisit();
    }
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ShopGet.clearResponse();
    await VisitGet.clearResponse();
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    this.isLoadedShopData = false;
    await ShopGet.get();
    if (!ShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedShopData = true;
    return ShopGet.isSuccess;
  }

  /**
   * グラフデータを取得する処理
   */
  async fetchVisit(): Promise<boolean> {
    this.isLoadedVisitData = false;
    await Flash.clear();
    const visitGetRequest: VisitGetRequest = {
      startDate: this.visitInputOptions.startDate,
      endDate: this.visitInputOptions.endDate,
      viewType: this.visitInputOptions.viewType,
      searchShopId:
        this.visitInputOptions.selectShop == null
          ? null
          : Number(this.visitInputOptions.selectShop.id)
    };
    await VisitGet.get(visitGetRequest);
    if (!VisitGet.isSuccess) {
      await Flash.setErrorNow({
        message: VisitGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedVisitData = true;
    return VisitGet.isSuccess;
  }

  updateTotalCountOnDrillDowned(value: number) {
    this.totalCountOnDrillDowned = value;
  }
}
