import { Component, Mixins, Prop, Vue, Watch } from "vue-property-decorator";
import UlPieChart from "@/models/analysis/ul-pie-chart";
import AnalysisBase from "@/models/analysis/analysis-base";
import AttributesStatisticsGet from "@/store/analysis/attributes-statistics/get";

@Component({
  components: {
    UlPieChart: UlPieChart
  }
})
export default class AnalysisAttributesStatisticsChart extends Mixins(
  AnalysisBase
) {
  @Prop() tabIndex!: number;

  get pushChartData() {
    return this.getPieChartData(AttributesStatisticsGet.getPushData);
  }

  get pushChartOptions() {
    return this.getPieChartOptions(AttributesStatisticsGet.getPushData);
  }

  get genderChartData() {
    return this.getPieChartData(AttributesStatisticsGet.getGenderData);
  }

  get genderChartOptions() {
    return this.getPieChartOptions(AttributesStatisticsGet.getGenderData);
  }

  get periodChartData() {
    return this.getPieChartData(AttributesStatisticsGet.getPeriodData);
  }

  get periodChartOptions() {
    return this.getPieChartOptions(AttributesStatisticsGet.getPeriodData);
  }

  get profile1ChartData() {
    return this.getPieChartData(AttributesStatisticsGet.getProfile1Data);
  }

  get profile1ChartOptions() {
    return this.getPieChartOptions(AttributesStatisticsGet.getProfile1Data);
  }

  get profile2ChartData() {
    return this.getPieChartData(AttributesStatisticsGet.getProfile2Data);
  }

  get profile2ChartOptions() {
    return this.getPieChartOptions(AttributesStatisticsGet.getProfile2Data);
  }

  get profile3ChartData() {
    return this.getPieChartData(AttributesStatisticsGet.getProfile3Data);
  }

  get profile3ChartOptions() {
    return this.getPieChartOptions(AttributesStatisticsGet.getProfile3Data);
  }

  get profile4ChartData() {
    return this.getPieChartData(AttributesStatisticsGet.getProfile4Data);
  }

  get profile4ChartOptions() {
    return this.getPieChartOptions(AttributesStatisticsGet.getProfile4Data);
  }

  get profile5ChartData() {
    return this.getPieChartData(AttributesStatisticsGet.getProfile5Data);
  }

  get profile5ChartOptions() {
    return this.getPieChartOptions(AttributesStatisticsGet.getProfile5Data);
  }

  /**
   * createdライフサイクルフック
   */
  async created() {}

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {}
}
