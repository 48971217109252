import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as IconTapProfileAPI from "@/api/analysis/icon-tap-profile";
import { isSuccess } from "@/api/response";
import { IconTapProfileGetRequest } from "@/api/analysis/icon-tap-profile/request";
import {
  IconTapProfileGetResponse,
  IconTapProfileGetResult
} from "@/api/analysis/icon-tap-profile/response";

const MODULE_NAME = "analysis/icon-tap-profile/get";

/**
 *  アイコンタップ数プロフィール属性API（analysis-icon-tap-profile）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: IconTapProfileGetRequest = {} as IconTapProfileGetRequest;
  getResponse: IconTapProfileGetResponse = {} as IconTapProfileGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getResult() {
    if (this.getResponse.results) {
      return this.getResponse.results;
    } else {
      return {} as IconTapProfileGetResult;
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }
  // MutationActions
  @MutationAction
  async get(getRequest: IconTapProfileGetRequest) {
    const getResponse = await IconTapProfileAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as IconTapProfileGetResponse
    };
  }
}

export default getModule(Get);
