import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { RegisterResponse } from "@/api/dashboard/response";
import * as api from "@/api/dashboard";
import { isSuccess } from "@/api/response";
import { RegisterRequest } from "@/api/dashboard/request";

const MODULE_NAME = "dashboard/register";

/**
 * 分析データ表示順設定登録API（/sort-dashboard）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Register extends VuexModule {
  registerResponse: RegisterResponse = {} as RegisterResponse;

  get isSuccess() {
    return isSuccess(this.registerResponse);
  }

  get getMessage() {
    return this.registerResponse.message;
  }

  // MutationActions
  @MutationAction
  async register(request: RegisterRequest) {
    const registerResponse = await api.register(request);
    return {
      registerResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      registerResponse: {} as RegisterResponse
    };
  }
}

export default getModule(Register);
