import { Component, Mixins, Watch } from "vue-property-decorator";
import AnalysisBase from "@/models/analysis/analysis-base";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import Flash, { ErrorAlert } from "@/store/common/flash";
import ShopGet from "@/store/shop/getMainShop";
import IconTapGet from "@/store/analysis/icon-tap/get";
import Admin from "@/store/admin/admin";
import IconTapChart from "@/views/analysis/IconTapChart.vue";
import { IconTapListGetRequest } from "@/api/analysis/icon-tap/request";
import IconTapListGet from "@/store/analysis/icon-tap-list/get";
import * as IconTapListCSV from "@/api/analysis/icon-tap-list-csv";
import { isSuccess } from "@/api/response";

export interface IconTapInputOptions {
  startDate: string;
  endDate: string;
  viewType: number;
  selectShop: { id: string; name: string } | null;
}

@Component({
  components: {
    UlContentHeader,
    UIDatePicker,
    IconTapChart
  }
})
export default class AnalysisIconTap extends Mixins(AnalysisBase) {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "アイコンタップ数";
  headingSub = "Number of Icon Taps";
  breadCrumbs = [{ text: "アイコンタップ数", disabled: true }];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  viewTypes = [
    { label: "日別", value: 1 },
    { label: "月別", value: 2 }
  ];

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoadedShopData = false;
  isLoadedIconTapData = false;
  isLoadedIconTapListData = false;

  // DatePickerの表示フラグ
  startDateModal = null;
  endDateModal = null;

  // 検索入力オプション
  iconTapInputOptions: IconTapInputOptions = {
    startDate: this.initDates[0],
    endDate: this.initDates[1],
    viewType: 1,
    selectShop: null
  };

  totalCountOnDrillDowned = 0;

  /**
   * 総件数
   */
  get totalCount() {
    return this.totalCountOnDrillDowned > 0
      ? this.totalCountOnDrillDowned
      : IconTapGet.getTotalCount;
  }

  get iconTapData() {
    return IconTapGet.getData;
  }

  get startDateForView() {
    return this.replaceHyphenToSlash(this.iconTapInputOptions.startDate);
  }
  set startDateForView(date) {
    this.iconTapInputOptions.startDate = this.replaceSlashToHyphen(date);
  }

  get endDateForView() {
    return this.replaceHyphenToSlash(this.iconTapInputOptions.endDate);
  }
  set endDateForView(date) {
    this.iconTapInputOptions.endDate = this.replaceSlashToHyphen(date);
  }

  get shopItems() {
    return ShopGet.getItems;
  }

  get initDates() {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDate = new Date(y, m, 1);
    const lastDate = new Date(y, m + 1, 0);
    return [this.stringFromDate(firstDate), this.stringFromDate(lastDate)];
  }

  get tableHeaders() {
    const headers: { text: string; data: number | string }[] = [];
    IconTapListGet.getLabels.map((l, i) => {
      const keys = Object.keys(IconTapListGet.getItems[0]);
      headers.push({ text: l, data: keys[i] });
    });
    return headers;
  }

  get IconTapListItems() {
    return IconTapListGet.getItems;
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    if (this.editFocused || !this.isNeededActiveOnDashBoard) {
      return false;
    }

    await this.fetchShopList();
    if (!Admin.isAdmin) {
      this.iconTapInputOptions.selectShop = ShopGet.getItems[0];
      this.fetchRootChartData();
    }
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ShopGet.clearResponse();
    await IconTapGet.clearResponse();
    await IconTapListGet.clearResponse();
  }

  async fetchRootChartData() {
    await this.fetchIconTap();
    await this.fetchIconTapList();
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    this.isLoadedShopData = false;
    await ShopGet.get();
    if (!ShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedShopData = true;
    return ShopGet.isSuccess;
  }

  /**
   * グラフデータを取得する処理
   */
  async fetchIconTap(): Promise<boolean> {
    this.isLoadedIconTapData = false;
    await Flash.clear();
    const iconTapGetRequest: IconTapListGetRequest = {
      startDate: this.iconTapInputOptions.startDate,
      endDate: this.iconTapInputOptions.endDate,
      viewType: this.iconTapInputOptions.viewType,
      searchShopId:
        this.iconTapInputOptions.selectShop == null
          ? null
          : Number(this.iconTapInputOptions.selectShop.id)
    };
    await IconTapGet.get(iconTapGetRequest);
    if (!IconTapGet.isSuccess) {
      await Flash.setErrorNow({
        message: IconTapGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedIconTapData = true;
    return IconTapGet.isSuccess;
  }

  async fetchIconTapList(): Promise<boolean> {
    this.isLoadedIconTapListData = false;
    await Flash.clear();
    const iconTapListGetRequest: IconTapListGetRequest = {
      startDate: this.iconTapInputOptions.startDate,
      endDate: this.iconTapInputOptions.endDate,
      viewType: this.iconTapInputOptions.viewType,
      searchShopId:
        this.iconTapInputOptions.selectShop != null
          ? Number(this.iconTapInputOptions.selectShop.id)
          : null
    };
    await IconTapListGet.get(iconTapListGetRequest);
    if (!IconTapListGet.isSuccess) {
      await Flash.setErrorNow({
        message: IconTapListGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedIconTapListData = true;
    return IconTapListGet.isSuccess;
  }

  async fetchIconTapListCSV() {
    await Flash.clear();
    const iconTapListCSVGetRequest: IconTapListGetRequest = {
      startDate: this.iconTapInputOptions.startDate,
      endDate: this.iconTapInputOptions.endDate,
      viewType: this.iconTapInputOptions.viewType,
      searchShopId:
        this.iconTapInputOptions.selectShop != null
          ? Number(this.iconTapInputOptions.selectShop.id)
          : null
    };

    const iconTapListCSVGetResponse = await IconTapListCSV.get(
      iconTapListCSVGetRequest
    );
    if (
      iconTapListCSVGetResponse &&
      iconTapListCSVGetResponse.statusCd !== 200
    ) {
      await Flash.setErrorNow({
        message: iconTapListCSVGetResponse.message,
        showReloadButton: true
      } as ErrorAlert);
      return false;
    }
    this.saveCSV(
      iconTapListCSVGetResponse.results.csvString,
      iconTapListCSVGetResponse.results.fileName
    );
  }

  updateTotalCountOnDrillDowned(value: number) {
    this.totalCountOnDrillDowned = value;
  }
}
