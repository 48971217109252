import service from "@/api/service";
import { CouponListGetRequest } from "@/api/analysis/coupon-list/request";
import { DownloadCSVResponse } from "@/api/response";

/**
 * クーポン利用リストCSV出力APIをコールします。
 *
 * @param getRequest クーポン利用リストCSV出力APIのリクエストボディ
 * @return response
 */
export async function get(getRequest: CouponListGetRequest) {
  const response = await service.postReceiveCSV(
    "/analysis-coupon-list-csv",
    getRequest
  );
  return response as DownloadCSVResponse;
}
