import service from "@/api/service";
import { RankStampUserCSVGetRequest } from "@/api/analysis/rank-stamp-user-csv/request";
import { DownloadCSVResponse } from "@/api/response";

/**
 * ランク(スタンプ)ユーザーCSV出力APIをコールします。
 *
 * @param getRequest ランク(スタンプ)ユーザーCSV出力APIのリクエストボディ
 * @return response
 */
export async function get(getRequest: RankStampUserCSVGetRequest) {
  const response = await service.postReceiveCSV(
    "/analysis-rank-stamp-user-csv",
    getRequest)
  ;
  return response as DownloadCSVResponse;
}
