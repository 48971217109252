import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as AnalysisCouponReadAPI from "@/api/analysis/coupon-read";
import { isSuccess } from "@/api/response";
import { CouponReadGetRequest } from "@/api/analysis/coupon-read/request";
import { CouponReadGetResponse } from "@/api/analysis/coupon-read/response";

const MODULE_NAME = "analysis/coupon-read/get";

/**
 *  クーポン閲覧ステータスAPI（/analysis-coupon-read）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: CouponReadGetRequest = {} as CouponReadGetRequest;
  getResponse: CouponReadGetResponse = {} as CouponReadGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getData() {
    if (this.getResponse.results) {
      return this.getResponse.results.data || null;
    } else {
      return [];
    }
  }

  get getDate() {
    if (this.getResponse.results) {
      return this.getResponse.results.date;
    } else {
      return "";
    }
  }

  get getTotalCount() {
    if (this.getResponse.results) {
      return this.getResponse.results.totalCount || 0;
    } else {
      return 0;
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }

  // MutationActions
  @MutationAction
  async get(getRequest: CouponReadGetRequest) {
    const getResponse = await AnalysisCouponReadAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as CouponReadGetResponse
    };
  }
}

export default getModule(Get);
