import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as AppDownloadProfileAPI from "@/api/analysis/app-download-profile";
import { isSuccess } from "@/api/response";

import { AppDownloadProfileGetRequest } from "@/api/analysis/app-download-profile/request";
import {
  AppDownloadProfileGetResponse,
  AppDownloadProfileGetResult
} from "@/api/analysis/app-download-profile/response";
import { AnalysisGraphData } from "@/api/analysis/common/response";

const MODULE_NAME = "analysis/app-download-profile/get";

/**
 *  プロフィール属性(アプリDL)API（/analysis-app-download-profile）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: AppDownloadProfileGetRequest = {} as AppDownloadProfileGetRequest;
  getResponse: AppDownloadProfileGetResponse = {} as AppDownloadProfileGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getGenderData() {
    if (this.getResponse.results && this.getResponse.results["genderData"]) {
      return this.getResponse.results.genderData.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getPeriodData() {
    if (this.getResponse.results && this.getResponse.results["periodData"]) {
      return this.getResponse.results.periodData.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getProfile1Data() {
    if (this.getResponse.results && this.getResponse.results["profile1Data"]) {
      return this.getResponse.results.profile1Data.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getProfile2Data() {
    if (this.getResponse.results && this.getResponse.results["profile2Data"]) {
      return this.getResponse.results.profile2Data.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getProfile3Data() {
    if (this.getResponse.results && this.getResponse.results["profile3Data"]) {
      return this.getResponse.results.profile3Data.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getProfile4Data() {
    if (this.getResponse.results && this.getResponse.results["profile4Data"]) {
      return this.getResponse.results.profile4Data.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getProfile5Data() {
    if (this.getResponse.results && this.getResponse.results["profile5Data"]) {
      return this.getResponse.results.profile5Data.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }

  // MutationActions
  @MutationAction
  async get(getRequest: AppDownloadProfileGetRequest) {
    const getResponse = await AppDownloadProfileAPI.get(getRequest);
    return {
      getResponse,
      getRequest
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as AppDownloadProfileGetResponse
    };
  }
}

export default getModule(Get);
