import { Component, Emit, Mixins, Prop, Watch } from "vue-property-decorator";
import UlPieChart from "@/models/analysis/ul-pie-chart";
import Flash, { ErrorAlert } from "@/store/common/flash";
import AnalysisBase, { ChartElement } from "@/models/analysis/analysis-base";
import { AnalysisGraphData } from "@/api/analysis/common/response";
import { CouponUseGetRequest } from "@/api/analysis/coupon-use/request";
import CouponUseGet from "@/store/analysis/coupon-use/get";
import { CouponReadInputOptions } from "@/models/analysis/coupon";
import CouponReadGet from "@/store/analysis/coupon-read/get";
import CouponProfileGet from "@/store/analysis/coupon-profile/get";
import { CouponProfileGetRequest } from "@/api/analysis/coupon-profile/request";
import { CouponProfileGetResult } from "@/api/analysis/coupon-profile/response";
import { CouponUseCSVGetRequest } from "@/api/analysis/coupon-user-csv/request";
import * as CouponUserCSV from "@/api/analysis/coupon-user-csv";
import { isSuccess } from "@/api/response";
import { saveAs } from "file-saver";

@Component({
  components: {
    UlPieChart
  }
})
export default class AnalysisCouponChart extends Mixins(AnalysisBase) {
  @Prop() inputOptions!: CouponReadInputOptions;
  @Prop() data!: AnalysisGraphData;

  @Watch("totalCountOnDrillDowned")
  didUpdateTotalCount(value: number) {
    this.updateTotalCountOnDrillDowned(value);
  }

  @Emit()
  public updateTotalCountOnDrillDowned(value: number) {
    return { totalCount: value, name: this.totalCountName };
  }

  isLoadedCouponUseData = false;
  isLoadedCouponProfileData = false;
  drillDownHierarchy = 0;
  useStatus = "";
  useStatusName = "";

  totalCountOnDrillDowned = 0;
  totalCounts: number[] = [0];

  totalCountName = "総ユーザ発行枚数";
  totalCountNames: string[] = ["総ユーザ発行枚数"];

  get couponReadChartData() {
    return this.getPieChartData(this.data);
  }

  get couponReadChartOption() {
    return this.getPieChartOptions(this.data, this.drillDownCouponUse);
  }

  get couponUseChartData() {
    return this.getPieChartData(CouponUseGet.getData);
  }

  get couponUseChartOption() {
    return this.getPieChartOptions(
      CouponUseGet.getData,
      this.drillDownCouponProfile
    );
  }

  get genderChartData() {
    return this.getPieChartData(CouponProfileGet.getGenderData);
  }

  get genderChartOptions() {
    return this.getPieChartOptions(
      CouponProfileGet.getGenderData,
      this.fetchCouponUserCSVByGender
    );
  }

  get periodChartData() {
    return this.getPieChartData(CouponProfileGet.getPeriodData);
  }

  get periodChartOptions() {
    return this.getPieChartOptions(
      CouponProfileGet.getPeriodData,
      this.fetchCouponUserCSVByPeriod
    );
  }

  get profile1ChartData() {
    return this.getPieChartData(CouponProfileGet.getProfile1Data);
  }

  get profile1ChartOptions() {
    return this.getPieChartOptions(
      CouponProfileGet.getProfile1Data,
      this.fetchCouponUserCSVByProfile1
    );
  }

  get profile2ChartData() {
    return this.getPieChartData(CouponProfileGet.getProfile2Data);
  }

  get profile2ChartOptions() {
    return this.getPieChartOptions(
      CouponProfileGet.getProfile2Data,
      this.fetchCouponUserCSVByProfile2
    );
  }

  get profile3ChartData() {
    return this.getPieChartData(CouponProfileGet.getProfile3Data);
  }

  get profile3ChartOptions() {
    return this.getPieChartOptions(
      CouponProfileGet.getProfile3Data,
      this.fetchCouponUserCSVByProfile3
    );
  }

  get profile4ChartData() {
    return this.getPieChartData(CouponProfileGet.getProfile4Data);
  }

  get profile4ChartOptions() {
    return this.getPieChartOptions(
      CouponProfileGet.getProfile4Data,
      this.fetchCouponUserCSVByProfile4
    );
  }

  get profile5ChartData() {
    return this.getPieChartData(CouponProfileGet.getProfile5Data);
  }

  get profile5ChartOptions() {
    return this.getPieChartOptions(
      CouponProfileGet.getProfile5Data,
      this.fetchCouponUserCSVByProfile5
    );
  }

  /**
   * createdライフサイクルフック
   */
  async created() {}

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {}

  async fetchCouponUse(request: CouponUseGetRequest): Promise<boolean> {
    this.isLoadedCouponUseData = false;
    await Flash.clear();
    await CouponUseGet.get(request);
    if (!CouponUseGet.isSuccess) {
      await Flash.setErrorNow({
        message: CouponUseGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedCouponUseData = true;
    return CouponUseGet.isSuccess;
  }

  async drillDownCouponUse(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0)
    ) {
      return;
    }

    const element = activeElements[0] as ChartElement;
    const graphData = CouponReadGet.getData as AnalysisGraphData;
    if (graphData.labels[element._index] === "未閲覧") {
      return;
    }

    if (event.ctrlKey || event.shiftKey) {
      this.fetchCouponUserCSV(1, element._index);
      return;
    }

    const couponUseGetRequest: CouponUseGetRequest = {
      couponId:
        this.inputOptions.selectCoupon != null
          ? this.inputOptions.selectCoupon.id
          : null,
      shopId:
        this.inputOptions.selectShop != null
          ? Number(this.inputOptions.selectShop.id)
          : null,
      date: ""
    };

    couponUseGetRequest.date = CouponReadGet.getDate;
    await this.fetchCouponUse(couponUseGetRequest);

    const datasetsData = this.data.datasets[0].data;
    if (datasetsData != null) {
      this.totalCountOnDrillDowned = Number(datasetsData[element._index]);
      this.totalCounts[1] = this.totalCountOnDrillDowned;
      this.totalCountName = "総ユーザ閲覧枚数";
      this.totalCountNames[1] = this.totalCountName;
    }
    this.drillDownHierarchy = 1;
  }

  async fetchCouponUserCSV(graphType: number, elementIndex: number) {
    if (
      this.inputOptions.selectShop == null ||
      this.inputOptions.selectCoupon == null
    ) {
      return;
    }

    const couponUseCSVGetRequest: CouponUseCSVGetRequest = {
      shopId: Number(this.inputOptions.selectShop.id),
      couponId: this.inputOptions.selectCoupon.id,
      graphType: graphType
    };

    if (graphType === 1) {
      const graphData = CouponReadGet.getData as AnalysisGraphData;
      couponUseCSVGetRequest.readStatus = graphData.labels[elementIndex];
    } else if (graphType === 2) {
      couponUseCSVGetRequest.useStatus = this.useStatusName;
    } else if (graphType >= 3) {
      couponUseCSVGetRequest.useStatus = this.useStatusName;

      let attributeData = {} as AnalysisGraphData;
      if (graphType === 3) {
        attributeData = CouponProfileGet.getGenderData;
      } else if (graphType === 4) {
        attributeData = CouponProfileGet.getPeriodData;
      } else if (graphType === 5) {
        attributeData = CouponProfileGet.getProfile1Data;
      } else if (graphType === 6) {
        attributeData = CouponProfileGet.getProfile2Data;
      } else if (graphType === 7) {
        attributeData = CouponProfileGet.getProfile3Data;
      } else if (graphType === 8) {
        attributeData = CouponProfileGet.getProfile4Data;
      } else if (graphType === 9) {
        attributeData = CouponProfileGet.getProfile5Data;
      }
      couponUseCSVGetRequest.profileData = attributeData.labels[elementIndex];
    }

    const couponUseListCSVGetResponse = await CouponUserCSV.get(
      couponUseCSVGetRequest
    );

    if (
      couponUseListCSVGetResponse &&
      couponUseListCSVGetResponse.statusCd !== 200
    ) {
      await Flash.setErrorNow({
        message: couponUseListCSVGetResponse.message,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.saveCSV(
      couponUseListCSVGetResponse.results.csvString,
      couponUseListCSVGetResponse.results.fileName
    );
  }

  async fetchCouponProfile(request: CouponProfileGetRequest): Promise<boolean> {
    this.isLoadedCouponProfileData = false;
    await Flash.clear();
    await CouponProfileGet.get(request);
    if (!CouponProfileGet.isSuccess) {
      await Flash.setErrorNow({
        message: CouponProfileGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedCouponProfileData = true;
    return CouponProfileGet.isSuccess;
  }

  async drillDownCouponProfile(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0) ||
      this.isDashboard
    ) {
      return;
    }

    const element = activeElements[0] as ChartElement;
    const graphData = CouponUseGet.getData as AnalysisGraphData;
    this.useStatus = graphData.labelsData[element._index];
    this.useStatusName = graphData.labels[element._index];

    // NEW_DEV-2046 cyber start
    if (graphData.labels[element._index] === "未利用") {
      return;
    }
    // NEW_DEV-2046 cyber end

    if (event.ctrlKey || event.shiftKey) {
      const element = activeElements[0] as ChartElement;
      this.fetchCouponUserCSV(2, element._index);
      return;
    }

    const pushProfileGetRequest: CouponProfileGetRequest = {
      shopId: CouponUseGet.getGetRequest.shopId,
      couponId: CouponUseGet.getGetRequest.couponId,
      date: CouponUseGet.getGetRequest.date,
      useStatus: this.useStatus,
      totalCount: CouponUseGet.getTotalCount
    };

    await this.fetchCouponProfile(pushProfileGetRequest);

    const datasetsData = graphData.datasets[0].data;
    if (datasetsData != null) {
      this.totalCountOnDrillDowned = Number(datasetsData[element._index]);
      this.totalCounts[2] = this.totalCountOnDrillDowned;
      this.totalCountName = "利用分布（プロフィール属性別）";
      this.totalCountNames[2] = this.totalCountName;
    }
    this.drillDownHierarchy = 2;
  }

  async fetchCouponUserCSVByGender(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchCouponUserCSVByProfile(3, event, activeElements);
  }

  async fetchCouponUserCSVByPeriod(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchCouponUserCSVByProfile(4, event, activeElements);
  }

  async fetchCouponUserCSVByProfile1(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchCouponUserCSVByProfile(5, event, activeElements);
  }

  async fetchCouponUserCSVByProfile2(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchCouponUserCSVByProfile(6, event, activeElements);
  }

  async fetchCouponUserCSVByProfile4(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchCouponUserCSVByProfile(8, event, activeElements);
  }

  async fetchCouponUserCSVByProfile5(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchCouponUserCSVByProfile(9, event, activeElements);
  }

  async fetchCouponUserCSVByProfile3(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchCouponUserCSVByProfile(7, event, activeElements);
  }

  async fetchCouponUserCSVByProfile(
    graphType: number,
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0)
    ) {
      return;
    }

    if (event.ctrlKey || event.shiftKey) {
      const element = activeElements[0] as ChartElement;
      await this.fetchCouponUserCSV(graphType, element._index);
    }
  }

  onClickBack() {
    this.drillDownHierarchy -= 1;
    this.totalCountOnDrillDowned = this.totalCounts[this.drillDownHierarchy];
    this.totalCountName = this.totalCountNames[this.drillDownHierarchy];
  }
}
