import { Component, Emit, Mixins, Prop, Watch } from "vue-property-decorator";
import UlBarChart from "@/models/analysis/ul-bar-chart";
import UlPieChart from "@/models/analysis/ul-pie-chart";
import Flash, { ErrorAlert } from "@/store/common/flash";
import AnalysisBase from "@/models/analysis/analysis-base";
import { ChartElement } from "@/models/analysis/analysis-base";
import { AnalysisGraphData } from "@/api/analysis/common/response";
import { AppDownloadProfileGetRequest } from "@/api/analysis/app-download-profile/request";
import AppDownloadGet from "@/store/analysis/app-download/get";
import AppDownloadProfileGet from "@/store/analysis/app-download-profile/get";
import { AppDownloadInputOptions } from "@/models/analysis/app-download";
import { AppDownloadUserCSVGetRequest } from "@/api/analysis/app-download-user-csv/request";
import * as AppDownloadUserCSVGet from "@/api/analysis/app-download-user-csv";

@Component({
  components: {
    UlBarChart: UlBarChart,
    UlPieChart: UlPieChart
  }
})
export default class AnalysisAppDownloadChart extends Mixins(AnalysisBase) {
  @Prop() inputOptions!: AppDownloadInputOptions;
  @Prop() data!: AnalysisGraphData;
  @Prop() tabIndex!: number;

  @Watch("totalCountOnDrillDowned")
  didUpdateTotalCount(value: number) {
    this.updateTotalCountOnDrillDowned(value);
  }

  @Emit()
  public updateTotalCountOnDrillDowned(value: number) {
    return value;
  }

  chart: Record<string, any> = {};

  // ローディングステータス
  isLoadedAppDownloadProfile = false;

  drillDownHierarchy = 0;
  totalCountOnDrillDowned = 0;

  get appDownloadChartData() {
    return this.getBarChartData(this.data);
  }

  get appDownloadChartOption() {
    return this.getBarChartOptions(
      this.data,
      true,
      true,
      this.drillDownAppDownloadProfile
    );
  }

  get genderChartData() {
    return this.getPieChartData(AppDownloadProfileGet.getGenderData);
  }

  get genderChartOptions() {
    return this.getPieChartOptions(
      AppDownloadProfileGet.getGenderData,
      this.fetchAppDownloadUserCSVByGender
    );
  }

  get periodChartData() {
    return this.getPieChartData(AppDownloadProfileGet.getPeriodData);
  }

  get periodChartOptions() {
    return this.getPieChartOptions(
      AppDownloadProfileGet.getPeriodData,
      this.fetchAppDownloadUserCSVByPeriod
    );
  }

  get profile1ChartData() {
    return this.getPieChartData(AppDownloadProfileGet.getProfile1Data);
  }

  get profile1ChartOptions() {
    return this.getPieChartOptions(
      AppDownloadProfileGet.getProfile1Data,
      this.fetchAppDownloadUserCSVByProfile1
    );
  }

  get profile2ChartData() {
    return this.getPieChartData(AppDownloadProfileGet.getProfile2Data);
  }

  get profile2ChartOptions() {
    return this.getPieChartOptions(
      AppDownloadProfileGet.getProfile2Data,
      this.fetchAppDownloadUserCSVByProfile2
    );
  }

  get profile3ChartData() {
    return this.getPieChartData(AppDownloadProfileGet.getProfile3Data);
  }

  get profile3ChartOptions() {
    return this.getPieChartOptions(
      AppDownloadProfileGet.getProfile3Data,
      this.fetchAppDownloadUserCSVByProfile3
    );
  }

  get profile4ChartData() {
    return this.getPieChartData(AppDownloadProfileGet.getProfile4Data);
  }

  get profile4ChartOptions() {
    return this.getPieChartOptions(
      AppDownloadProfileGet.getProfile4Data,
      this.fetchAppDownloadUserCSVByProfile4
    );
  }

  get profile5ChartData() {
    return this.getPieChartData(AppDownloadProfileGet.getProfile5Data);
  }

  get profile5ChartOptions() {
    return this.getPieChartOptions(
      AppDownloadProfileGet.getProfile5Data,
      this.fetchAppDownloadUserCSVByProfile5
    );
  }

  /**
   * createdライフサイクルフック
   */
  async created() {}

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {}

  async fetchAppDownloadProfile(
    inputOptions: AppDownloadProfileGetRequest
  ): Promise<boolean> {
    this.isLoadedAppDownloadProfile = false;
    await Flash.clear();
    await AppDownloadProfileGet.get(inputOptions);
    if (!AppDownloadProfileGet.isSuccess) {
      await Flash.setErrorNow({
        message: AppDownloadProfileGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedAppDownloadProfile = true;
    return AppDownloadProfileGet.isSuccess;
  }

  onClickBack() {
    this.totalCountOnDrillDowned = 0;
    this.drillDownHierarchy = 0;
  }

  async drillDownAppDownloadProfile(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0) ||
      this.isDashboard
    ) {
      return;
    }

    const element = this.chart.getElementAtEvent(event)[0] as ChartElement;
    if (event.ctrlKey || event.shiftKey) {
      await this.fetchAppDownloadUserCSV(0, 0, element._index);
      return;
    }

    const appDownloadProfileGetRequest: AppDownloadProfileGetRequest = {
      startDate: this.inputOptions.startDate,
      endDate: this.inputOptions.endDate,
      date: this.data.labelsData[element._index],
      shopId:
        this.inputOptions.selectShop != null
          ? Number(this.inputOptions.selectShop.id)
          : null,
      viewType: this.inputOptions.viewType,
      categoryType: this.inputOptions.categoryType,
      type: 2
    };
    await this.fetchAppDownloadProfile(appDownloadProfileGetRequest);

    const data0 = this.data.datasets[0].data;
    const data1 = this.data.datasets[1].data;
    if (data0 != null && data1 != null) {
      this.totalCountOnDrillDowned = Number(data0[element._index]) + Number(data1[element._index]);
    }
    this.drillDownHierarchy = 1;
  }

  async fetchAppDownloadUserCSV(
    graphType: number,
    profileType: number,
    elementIndex: number
  ) {
    const appDownloadUserCSVGetRequest: AppDownloadUserCSVGetRequest = {
      searchShopId:
        this.inputOptions.selectShop != null
          ? Number(this.inputOptions.selectShop.id)
          : null,
      startDate: this.inputOptions.startDate,
      endDate: this.inputOptions.endDate,
      viewType: this.inputOptions.viewType,
      categoryType: this.inputOptions.categoryType,
      graphType: graphType,
      profileType: profileType
    };

    appDownloadUserCSVGetRequest.horizontalAxis =
      graphType == 0
        ? this.data.labelsData[elementIndex]
        : AppDownloadProfileGet.getGetRequest.date;

    if (graphType == 1) {
      appDownloadUserCSVGetRequest.profileData =
        AppDownloadProfileGet.getProfile1Data.labels[elementIndex];
    } else if (graphType == 2) {
      appDownloadUserCSVGetRequest.profileData =
        AppDownloadProfileGet.getProfile2Data.labels[elementIndex];
    } else if (graphType == 3) {
      appDownloadUserCSVGetRequest.profileData =
        AppDownloadProfileGet.getProfile3Data.labels[elementIndex];
    } else if (graphType == 4) {
      appDownloadUserCSVGetRequest.profileData =
        AppDownloadProfileGet.getProfile4Data.labels[elementIndex];
    } else if (graphType == 5) {
      appDownloadUserCSVGetRequest.profileData =
        AppDownloadProfileGet.getProfile5Data.labels[elementIndex];
    } else if (graphType == 6) {
      appDownloadUserCSVGetRequest.profileData =
        AppDownloadProfileGet.getGenderData.labels[elementIndex];
    } else if (graphType == 7) {
      appDownloadUserCSVGetRequest.profileData =
        AppDownloadProfileGet.getPeriodData.labels[elementIndex];
    }

    const appDownloadUserCSVGetResponse = await AppDownloadUserCSVGet.get(
      appDownloadUserCSVGetRequest
    );

    if (
      appDownloadUserCSVGetResponse &&
      appDownloadUserCSVGetResponse.statusCd !== 200
    ) {
      await Flash.setErrorNow({
        message: appDownloadUserCSVGetResponse.message,
        showReloadButton: false
      } as ErrorAlert);
      return false;
    }

    this.saveCSV(
      appDownloadUserCSVGetResponse.results.csvString,
      appDownloadUserCSVGetResponse.results.fileName
    );
  }

  async fetchAppDownloadUserCSVByProfile1(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchAppDownloadUserCSVByProfile(1, 2, event, activeElements);
  }

  async fetchAppDownloadUserCSVByProfile2(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchAppDownloadUserCSVByProfile(2, 2, event, activeElements);
  }

  async fetchAppDownloadUserCSVByProfile3(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchAppDownloadUserCSVByProfile(3, 2, event, activeElements);
  }

  async fetchAppDownloadUserCSVByProfile4(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchAppDownloadUserCSVByProfile(4, 2, event, activeElements);
  }

  async fetchAppDownloadUserCSVByProfile5(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchAppDownloadUserCSVByProfile(5, 2, event, activeElements);
  }

  async fetchAppDownloadUserCSVByGender(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchAppDownloadUserCSVByProfile(6, 2, event, activeElements);
  }

  async fetchAppDownloadUserCSVByPeriod(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchAppDownloadUserCSVByProfile(7, 2, event, activeElements);
  }

  async fetchAppDownloadUserCSVByProfile(
    graphType: number,
    profileType: number,
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0)
    ) {
      return;
    }

    if (event.ctrlKey || event.shiftKey) {
      const element = activeElements[0] as ChartElement;
      await this.fetchAppDownloadUserCSV(
        graphType,
        profileType,
        element._index
      );
    }
  }

  chartRendered(chart: Record<string, any>) {
    this.chart = chart;
  }
}
