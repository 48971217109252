import service from "@/api/service";
import { BookingAttributeGetRequest } from "@/api/analysis/booking-attribute/request";
import { BookingAttributeGetResponse } from "@/api/analysis/booking-attribute/response";

/**
 * 予約属性APIをコールします。
 *
 * @param getRequest 予約属性のリクエストボディ
 * @return GetResponse
 */
export async function get(getRequest: BookingAttributeGetRequest) {
  const response = await service.post(
    "/analysis-booking-attribute",
    getRequest
  );
  return response.data as BookingAttributeGetResponse;
}
