import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as StampAPI from "@/api/analysis/stamp";
import { isSuccess } from "@/api/response";
import { StampGetRequest } from "@/api/analysis/stamp/request";
import { StampGetData, StampGetResponse } from "@/api/analysis/stamp/response";

const MODULE_NAME = "analysis/stamp/get";

/**
 *  スタンプ取得API（/analysis-stamps）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: StampGetRequest = {} as StampGetRequest;
  getResponse: StampGetResponse = {} as StampGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getGrantData() {
    if (this.getResponse.results) {
      return this.getResponse.results.grantData;
    } else {
      return {} as StampGetData;
    }
  }

  get getTicketData() {
    if (this.getResponse.results) {
      return this.getResponse.results.ticketData;
    } else {
      return [];
    }
  }

  get getCardData() {
    if (this.getResponse.results) {
      return this.getResponse.results.cardData;
    } else {
      return [];
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }

  // MutationActions
  @MutationAction
  async get(getRequest: StampGetRequest) {
    const getResponse = await StampAPI.get(getRequest);
    return {
      getResponse,
      getRequest
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as StampGetResponse
    };
  }
}

export default getModule(Get);
