import service from "@/api/service";
import { PointOriginalListGetResponse } from "@/api/analysis/point-original-list/response";
import { PointOriginalListGetRequest } from "@/api/analysis/point-original-list/request";

/**
 * 店舗ポイントカード利用数リスト取得APIをコールします。
 *
 * @param getRequest 店舗ポイントカード利用数リスト取得APIのリクエストボディ
 * @return PointOriginalListGetResponse
 */
export async function get(getRequest: PointOriginalListGetRequest) {
  const response = await service.post(
    "/analysis-point-original-list",
    getRequest
  );
  return response.data as PointOriginalListGetResponse;
}
