import { Component, Emit, Mixins, Prop, Watch } from "vue-property-decorator";
import UlBarChart from "@/models/analysis/ul-bar-chart";
import UlPieChart from "@/models/analysis/ul-pie-chart";
import AnalysisBase, { ChartElement } from "@/models/analysis/analysis-base";
import { AnalysisGraphData } from "@/api/analysis/common/response";
import Flash, { ErrorAlert } from "@/store/common/flash";
import { IconTapInputOptions } from "@/models/analysis/icon-tap";
import IconTapProfileGet from "@/store/analysis/icon-tap-profile/get";
import { IconTapProfileGetResult } from "@/api/analysis/icon-tap-profile/response";
import { IconTapProfileGetRequest } from "@/api/analysis/icon-tap-profile/request";
import IconTapGet from "@/store/analysis/icon-tap/get";

@Component({
  components: {
    UlBarChart: UlBarChart,
    UlPieChart: UlPieChart
  }
})
export default class AnalysisIconTapChart extends Mixins(AnalysisBase) {
  @Prop() inputOptions!: IconTapInputOptions;
  @Prop() data!: AnalysisGraphData;
  @Prop() tabIndex!: number;

  @Watch("totalCountOnDrillDowned")
  didUpdateTotalCount(value: number) {
    this.updateTotalCountOnDrillDowned(value);
  }

  @Emit()
  public updateTotalCountOnDrillDowned(value: number) {
    return value;
  }

  chart: Record<string, any> = {};

  // ローディングステータス
  isLoadedIconTapProfileData = false;

  drillDownHierarchy = 0;
  totalCountOnDrillDowned = 0;

  get iconTapChartData() {
    return this.getBarChartData(this.data);
  }

  get iconTapChartOptions() {
    return this.getBarChartOptions(
      this.data,
      false,
      false,
      this.drillDownIconTapProfile
    );
  }

  get genderChartData() {
    const result = IconTapProfileGet.getResult as IconTapProfileGetResult;
    const attributeData = result.genderData.data;
    return this.getPieChartData(attributeData);
  }

  get genderChartOptions() {
    const result = IconTapProfileGet.getResult as IconTapProfileGetResult;
    const attributeData = result.genderData.data;
    return this.getPieChartOptions(attributeData);
  }

  get periodChartData() {
    const result = IconTapProfileGet.getResult as IconTapProfileGetResult;
    const attributeData = result.periodData.data;
    return this.getPieChartData(attributeData);
  }

  get periodChartOptions() {
    const result = IconTapProfileGet.getResult as IconTapProfileGetResult;
    const attributeData = result.periodData.data;
    return this.getPieChartOptions(attributeData);
  }

  get profile1ChartData() {
    const result = IconTapProfileGet.getResult as IconTapProfileGetResult;
    const attributeData = result.profile1Data;
    if (!attributeData) {
      return null;
    }
    return this.getPieChartData(attributeData.data);
  }

  get profile1ChartOptions() {
    const result = IconTapProfileGet.getResult as IconTapProfileGetResult;
    const attributeData = result.profile1Data;
    if (!attributeData) {
      return false;
    }
    return this.getPieChartOptions(attributeData.data);
  }

  get profile2ChartData() {
    const result = IconTapProfileGet.getResult as IconTapProfileGetResult;
    const attributeData = result.profile2Data;
    if (!attributeData) {
      return false;
    }
    return this.getPieChartData(attributeData.data);
  }

  get profile2ChartOptions() {
    const result = IconTapProfileGet.getResult as IconTapProfileGetResult;
    const attributeData = result.profile2Data;
    if (!attributeData) {
      return false;
    }
    return this.getPieChartOptions(attributeData.data);
  }

  get profile3ChartData() {
    const result = IconTapProfileGet.getResult as IconTapProfileGetResult;
    const attributeData = result.profile3Data;
    if (!attributeData) {
      return false;
    }
    return this.getPieChartData(attributeData.data);
  }

  get profile3ChartOptions() {
    const result = IconTapProfileGet.getResult as IconTapProfileGetResult;
    const attributeData = result.profile3Data;
    if (!attributeData) {
      return false;
    }
    return this.getPieChartOptions(attributeData.data);
  }

  get profile4ChartData() {
    const result = IconTapProfileGet.getResult as IconTapProfileGetResult;
    const attributeData = result.profile4Data;
    if (!attributeData) {
      return false;
    }
    return this.getPieChartData(attributeData.data);
  }

  get profile4ChartOptions() {
    const result = IconTapProfileGet.getResult as IconTapProfileGetResult;
    const attributeData = result.profile4Data;
    if (!attributeData) {
      return false;
    }
    return this.getPieChartOptions(attributeData.data);
  }

  get profile5ChartData() {
    const result = IconTapProfileGet.getResult as IconTapProfileGetResult;
    const attributeData = result.profile5Data;
    if (!attributeData) {
      return false;
    }
    return this.getPieChartData(attributeData.data);
  }

  get profile5ChartOptions() {
    const result = IconTapProfileGet.getResult as IconTapProfileGetResult;
    const attributeData = result.profile5Data;
    if (!attributeData) {
      return false;
    }
    return this.getPieChartOptions(attributeData.data);
  }

  /**
   * createdライフサイクルフック
   */
  async created() {}

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {}

  async fetchIconTapProfile(
    inputOptions: IconTapProfileGetRequest
  ): Promise<boolean> {
    this.isLoadedIconTapProfileData = false;
    await Flash.clear();
    await IconTapProfileGet.get(inputOptions);
    if (!IconTapProfileGet.isSuccess) {
      await Flash.setErrorNow({
        message: IconTapProfileGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedIconTapProfileData = true;
    return IconTapProfileGet.isSuccess;
  }

  onClickBack() {
    this.totalCountOnDrillDowned = 0;
    this.drillDownHierarchy = 0;
  }

  async drillDownIconTapProfile(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined
  ) {
    if (
      !activeElements ||
      (activeElements && activeElements.length <= 0) ||
      this.isDashboard ||
      IconTapGet.getData.datasets[0].data == undefined
    ) {
      return;
    }

    const element = this.chart.getElementAtEvent(event)[0] as ChartElement;
    const visitAttributeGetRequest: IconTapProfileGetRequest = {
      startDate: this.inputOptions.startDate,
      endDate: this.inputOptions.endDate,
      shopId:
        this.inputOptions.selectShop != null
        ? Number(this.inputOptions.selectShop.id)
        : null,
      viewType: this.inputOptions.viewType,
      cassette_id: Number(IconTapGet.getData.labelsData[element._index]),
      topType: Number(IconTapGet.getTopType),
    };

    await this.fetchIconTapProfile(visitAttributeGetRequest);

    const data = this.data.datasets[element._datasetIndex].data;
    if (data != null) {
      this.totalCountOnDrillDowned = Number(data[element._index]);
    }
    this.drillDownHierarchy = 1;
  }

  chartRendered(chart: Record<string, any>) {
    this.chart = chart;
  }
}
