import service from "@/api/service";
import { StampCommonGetRequest } from "@/api/analysis/stamp-common/request";
import { StampCommonGetResponse } from "@/api/analysis/stamp-common/response";

/**
 * スタンプ付与数共通取得APIをコールします。
 *
 * @param getRequest スタンプ付与数共通取得APIのリクエストボディ
 * @return StampCommonGetResponse
 */
export async function get(getRequest: StampCommonGetRequest) {
  const response = await service.post("/analysis-stamp-common", getRequest);
  return response.data as StampCommonGetResponse;
}
