import service from "@/api/service";
import { PointBalanceGetRequest } from "@/api/analysis/point-balance/request";
import { PointBalanceGetResponse } from "@/api/analysis/point-balance/response";

/**
 ポイント残高別人数取得APIをコールします。
 *
 * @param getRequest ポイント残高別人数取得APIのリクエストボディ
 * @return PointBalanceGetResponse
 */
export async function get(getRequest: PointBalanceGetRequest) {
  const response = await service.post("/analysis-point-balance", getRequest);
  return response.data as PointBalanceGetResponse;
}
