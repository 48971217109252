import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { BookingAttributeGetRequest } from "@/api/analysis/booking-attribute/request";
import { BookingAttributeGetResponse } from "@/api/analysis/booking-attribute/response";
import * as AnalyticsReservationRankAPI from "@/api/analysis/booking-attribute";
import { isSuccess } from "@/api/response";
import { AnalysisGraphData } from "@/api/analysis/common/response";

const MODULE_NAME = "analysis/booking-attribute/get";

/**
 *  予約属性API（/analysis-booking-attribute）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: BookingAttributeGetRequest = {} as BookingAttributeGetRequest;
  getResponse: BookingAttributeGetResponse = {} as BookingAttributeGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getRankData() {
    if (this.getResponse.results && this.getResponse.results["rankData"]) {
      return this.getResponse.results.rankData.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getCustomerClassData() {
    if (
      this.getResponse.results &&
      this.getResponse.results["customerClassData"]
    ) {
      return this.getResponse.results.customerClassData.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }
  // MutationActions
  @MutationAction
  async get(getRequest: BookingAttributeGetRequest) {
    const getResponse = await AnalyticsReservationRankAPI.get(getRequest);
    return {
      getResponse,
      getRequest
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as BookingAttributeGetResponse
    };
  }
}

export default getModule(Get);
