import service from "@/api/service";
import { GetRequest, RegisterRequest } from "@/api/dashboard/request";
import { GetResponse, RegisterResponse } from "@/api/dashboard/response";

/**
 * 分析データ表示順設定取得APIをコールします。
 *
 * @param getRequest 分析データ表示順設定取得APIのリクエストボディ
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-dashboard", getRequest);
  return response.data as GetResponse;
}

/**
 * 分析データ表示順設定登録APIをコールします。
 *
 * @param registerRequest 分析データ表示順設定登録APIのリクエストボディ
 * @return RegisterResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post("/sort-dashboard", registerRequest);
  return response.data as RegisterResponse;
}
