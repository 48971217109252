import { Component, Emit, Mixins, Prop, Watch } from "vue-property-decorator";
import UlPieChart from "@/models/analysis/ul-pie-chart";
import AnalysisBase, { ChartElement } from "@/models/analysis/analysis-base";
import { isSuccess } from "@/api/response";
import Flash, { ErrorAlert } from "@/store/common/flash";
import { PointInputOptions } from "@/models/analysis/point";
import { AnalysisGraphData } from "@/api/analysis/common/response";
import { PointCompleteProfileGetRequest } from "@/api/analysis/point-complete-profile/request";
import PointCompleteProfileGet from "@/store/analysis/point-complete-profile/get";
import * as PointCompleteUserCSV from "@/api/analysis/point-complete-user-csv";
import { PointCompleteUserCSVGetRequest } from "@/api/analysis/point-complete-user-csv/request";

@Component({
  components: {
    UlPieChart
  }
})
export default class AnalysisPointCompleteChart extends Mixins(AnalysisBase) {
  @Prop() inputOptions!: PointInputOptions;
  @Prop() data!: AnalysisGraphData;

  @Watch("totalCountOnDrillDowned")
  didUpdateTotalCount(value: number) {
    this.updateTotalCountOnDrillDowned(value);
  }

  @Emit()
  public updateTotalCountOnDrillDowned(value: number) {
    return value;
  }

  chart: Record<string, any> = {};

  isLoadedPointCompleteProfile = false;
  drillDownHierarchy = 0;
  totalCountOnDrillDowned = 0;

  get pointCompleteChartData() {
    return this.getPieChartData(this.data);
  }

  get pointCompleteChartOption() {
    return this.getPieChartOptions(
      this.data,
      this.drillDownPointCompleteProfile
    );
  }

  get genderChartData() {
    return this.getPieChartData(PointCompleteProfileGet.getGenderData);
  }

  get genderChartOptions() {
    return this.getPieChartOptions(
      PointCompleteProfileGet.getGenderData,
      this.fetchPointCompleteUserCSVByGender
    );
  }

  get periodChartData() {
    return this.getPieChartData(PointCompleteProfileGet.getPeriodData);
  }

  get periodChartOptions() {
    return this.getPieChartOptions(
      PointCompleteProfileGet.getPeriodData,
      this.fetchPointCompleteUserCSVByPeriod
    );
  }

  get profile1ChartData() {
    return this.getPieChartData(PointCompleteProfileGet.getProfile1Data);
  }

  get profile1ChartOptions() {
    return this.getPieChartOptions(
      PointCompleteProfileGet.getProfile1Data,
      this.fetchPointCompleteUserCSVByProfile1
    );
  }

  get profile2ChartData() {
    return this.getPieChartData(PointCompleteProfileGet.getProfile2Data);
  }

  get profile2ChartOptions() {
    return this.getPieChartOptions(
      PointCompleteProfileGet.getProfile2Data,
      this.fetchPointCompleteUserCSVByProfile2
    );
  }

  get profile3ChartData() {
    return this.getPieChartData(PointCompleteProfileGet.getProfile3Data);
  }

  get profile3ChartOptions() {
    return this.getPieChartOptions(
      PointCompleteProfileGet.getProfile3Data,
      this.fetchPointCompleteUserCSVByProfile3
    );
  }

  get profile4ChartData() {
    return this.getPieChartData(PointCompleteProfileGet.getProfile4Data);
  }

  get profile4ChartOptions() {
    return this.getPieChartOptions(
      PointCompleteProfileGet.getProfile4Data,
      this.fetchPointCompleteUserCSVByProfile4
    );
  }

  get profile5ChartData() {
    return this.getPieChartData(PointCompleteProfileGet.getProfile5Data);
  }

  get profile5ChartOptions() {
    return this.getPieChartOptions(
      PointCompleteProfileGet.getProfile5Data,
      this.fetchPointCompleteUserCSVByProfile5
    );
  }

  get yesterday() {
    const date = new Date();
    return this.stringFromDate(
      new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)
    );
  }

  /**
   * createdライフサイクルフック
   */
  async created() {}

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {}

  async drillDownPointCompleteProfile(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0) ||
      this.isDashboard ||
      this.inputOptions.selectShop == null ||
      this.data.datasets[0].data == undefined
    ) {
      return false;
    }

    if (event.ctrlKey || event.shiftKey) {
      const element = activeElements[0] as ChartElement;
      this.fetchPointCompleteUserCSV(0, element._index);
      return;
    }

    const element = activeElements[0] as ChartElement;
    const pointCompleteProfileGetRequest: PointCompleteProfileGetRequest = {
      date: this.yesterday,
      shopId: Number(this.inputOptions.selectShop.id),
      useType: Number(this.data.labelsData[element._index]),
      totalCount: Number(this.data.datasets[0].data[element._index])
    };

    await this.fetchPointCompleteProfile(pointCompleteProfileGetRequest);

    this.totalCountOnDrillDowned = Number(
      this.data.datasets[0].data[element._index]
    );
    this.drillDownHierarchy = 1;
  }

  async fetchPointCompleteProfile(
    inputOptions: PointCompleteProfileGetRequest
  ): Promise<boolean> {
    this.isLoadedPointCompleteProfile = false;
    await Flash.clear();
    await PointCompleteProfileGet.get(inputOptions);
    if (!PointCompleteProfileGet.isSuccess) {
      await Flash.setErrorNow({
        message: PointCompleteProfileGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedPointCompleteProfile = true;
    return PointCompleteProfileGet.isSuccess;
  }

  async fetchPointCompleteUserCSVByProfile1(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchPointCompleteUserCSVByProfile(1, event, activeElements);
  }

  async fetchPointCompleteUserCSVByProfile2(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchPointCompleteUserCSVByProfile(2, event, activeElements);
  }

  async fetchPointCompleteUserCSVByProfile3(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchPointCompleteUserCSVByProfile(3, event, activeElements);
  }

  async fetchPointCompleteUserCSVByProfile4(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchPointCompleteUserCSVByProfile(4, event, activeElements);
  }

  async fetchPointCompleteUserCSVByProfile5(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchPointCompleteUserCSVByProfile(5, event, activeElements);
  }

  async fetchPointCompleteUserCSVByGender(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchPointCompleteUserCSVByProfile(6, event, activeElements);
  }

  async fetchPointCompleteUserCSVByPeriod(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchPointCompleteUserCSVByProfile(7, event, activeElements);
  }

  async fetchPointCompleteUserCSVByProfile(
    graphType: number,
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0)
    ) {
      return;
    }

    if (event.ctrlKey || event.shiftKey) {
      const element = activeElements[0] as ChartElement;
      await this.fetchPointCompleteUserCSV(graphType, element._index);
    }
  }

  async fetchPointCompleteUserCSV(graphType: number, elementIndex: number) {
    if (this.inputOptions.selectShop == null) {
      return false;
    }

    const pointCompleteUserCSVGetRequest: PointCompleteUserCSVGetRequest = {
      shopId: Number(this.inputOptions.selectShop.id),
      useType:
        graphType == 0
          ? Number(this.data.labelsData[elementIndex])
          : PointCompleteProfileGet.getResult.useType,
      graphType: graphType
    };

    if (graphType == 1) {
      pointCompleteUserCSVGetRequest.profileData =
        PointCompleteProfileGet.getResult.profile1Data.data.labels[
          elementIndex
        ];
    } else if (graphType == 2) {
      pointCompleteUserCSVGetRequest.profileData =
        PointCompleteProfileGet.getResult.profile2Data.data.labels[
          elementIndex
        ];
    } else if (graphType == 3) {
      pointCompleteUserCSVGetRequest.profileData =
        PointCompleteProfileGet.getResult.profile3Data.data.labels[
          elementIndex
        ];
    } else if (graphType == 4) {
      pointCompleteUserCSVGetRequest.profileData =
        PointCompleteProfileGet.getResult.profile4Data.data.labels[
          elementIndex
        ];
    } else if (graphType == 5) {
      pointCompleteUserCSVGetRequest.profileData =
        PointCompleteProfileGet.getResult.profile5Data.data.labels[
          elementIndex
        ];
    } else if (graphType == 6) {
      pointCompleteUserCSVGetRequest.profileData =
        PointCompleteProfileGet.getResult.genderData.data.labels[elementIndex];
    } else if (graphType == 7) {
      pointCompleteUserCSVGetRequest.profileData =
        PointCompleteProfileGet.getResult.periodData.data.labels[elementIndex];
    }

    const pointCompleteUserCSVGetResponse = await PointCompleteUserCSV.get(
      pointCompleteUserCSVGetRequest
    );
    if (!isSuccess(pointCompleteUserCSVGetResponse)) {
      await Flash.setErrorNow({
        message: pointCompleteUserCSVGetResponse.message,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.saveCSV(
      pointCompleteUserCSVGetResponse.results.csvString,
      pointCompleteUserCSVGetResponse.results.fileName
    );
  }

  onClickBack() {
    this.totalCountOnDrillDowned = 0;
    this.drillDownHierarchy = 0;
    this.totalCountOnDrillDowned = 0;
  }

  chartRendered(chart: Record<string, any>) {
    this.chart = chart;
  }
}
