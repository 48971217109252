import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { GetResponse } from "@/api/dashboard/response";
import * as api from "@/api/dashboard";
import { isSuccess } from "@/api/response";
import { GetRequest } from "@/api/dashboard/request";

const MODULE_NAME = "dashboard/get";

/**
 * 分析データ表示順設定取得API（/get-dashboard）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  getResponse: GetResponse = {} as GetResponse;

  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getItems() {
    if (this.getResponse.results) {
      return this.getResponse.results.items || [];
    } else {
      return [];
    }
  }

  get getWriteFlg() {
    if (this.getResponse.results) {
      return this.getResponse.writeFlg;
    } else {
      return 0;
    }
  }

  get getDispLimit() {
    if (this.getResponse.results) {
      return this.getResponse.dispLimit;
    } else {
      return 0;
    }
  }

  // MutationActions
  @MutationAction
  async get(request: GetRequest) {
    const getResponse = await api.get(request);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as GetResponse
    };
  }
}

export default getModule(Get);
