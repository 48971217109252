import { render, staticRenderFns } from "./AttributeStatisticsChart.vue?vue&type=template&id=12ecb936&scoped=true&"
import script from "../../models/analysis/attributes-statistics-chart.ts?vue&type=script&lang=ts&"
export * from "../../models/analysis/attributes-statistics-chart.ts?vue&type=script&lang=ts&"
import style0 from "../../styles/analysis/analysis.scss?vue&type=style&index=0&id=12ecb936&prod&lang=scss&scoped=true&"
import style1 from "./AttributeStatisticsChart.vue?vue&type=style&index=1&id=12ecb936&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12ecb936",
  null
  
)

export default component.exports