import { Vue, Component } from "vue-property-decorator";
import Draggable from "vuedraggable";
import AppDownload from "@/views/analysis/AppDownload.vue";
import RankPoint from "@/views/analysis/RankPoint.vue";
import RankStamp from "@/views/analysis/RankStamp.vue";
import Follow from "@/views/analysis/Follow.vue";
import PushNotification from "@/views/analysis/PushNotification.vue";
import Coupon from "@/views/analysis/Coupon.vue";
import Stamp from "@/views/analysis/Stamp.vue";
import DigitalTicket from "@/views/analysis/DigitalTicket.vue";
import Visit from "@/views/analysis/Visit.vue";
import IconTap from "@/views/analysis/IconTap.vue";
import Booking from "@/views/analysis/Booking.vue";
import AttributesStatistics from "@/views/analysis/AttributesStatistics.vue";
import Point from "@/views/analysis/Point.vue";

// NEW_DEV-2019 cyber start
import DigitalTicketPeriod from "@/views/analysis/DigitalTicketPeriod.vue";
import DigitalTicketGeneral from "@/views/analysis/DigitalTicketGeneral.vue";
import PointOriginal from "@/views/analysis/PointOriginal.vue";
// NEW_DEV-2019 cyber end

import DashBoardGet from "@/store/dashboard/get";
import DashBoardRegister from "@/store/dashboard/register";
import Flash, { ErrorAlert } from "@/store/common/flash";
import { Item } from "@/api/dashboard/response";
import _ from "lodash";
import { RegisterRequest } from "@/api/dashboard/request";

const editButtonLabelBefore = "分析項目を編集する";
const editButtonLabelEditing = "編集を終了する";
const components = [
  { menuId: 12, name: "AppDownload" },
  { menuId: 13, name: "RankPoint" },
  { menuId: 14, name: "RankStamp" },
  { menuId: 15, name: "AttributesStatistics" },
  { menuId: 16, name: "Follow" },
  { menuId: 17, name: "Booking" },
  { menuId: 18, name: "PushNotification" },
  { menuId: 19, name: "Coupon" },
  { menuId: 20, name: "Stamp" },
  { menuId: 21, name: "DigitalTicket" },
  { menuId: 24, name: "Point" },
  { menuId: 26, name: "Visit" },
  // NEW_DEV-2019 cyber start
  { menuId: 27, name: "IconTap" },
  { menuId: 22, name: "DigitalTicketPeriod" },
  { menuId: 23, name: "DigitalTicketGeneral" },
  { menuId: 25, name: "PointOriginal" }
  // NEW_DEV-2019 cyber end
];

interface DashBoardItem extends Item {
  component?: string;
}

// NEW_DEV-2019 cyber start
@Component({
  components: {
    Draggable: Draggable,
    AppDownload,
    RankPoint,
    RankStamp,
    Follow,
    PushNotification,
    Coupon,
    Stamp,
    DigitalTicket,
    Visit,
    IconTap,
    Booking,
    AttributesStatistics,
    Point,
    DigitalTicketPeriod,
    DigitalTicketGeneral,
    PointOriginal
  }
})
// NEW_DEV-2019 cyber end

export default class Index extends Vue {
  editFocused = false;
  editButtonLabel = editButtonLabelBefore;

  activeItems: DashBoardItem[] = [];
  inactiveItems: DashBoardItem[] = [];

  isLoadedItems = false;

  get disabledEditButton() {
    return DashBoardGet.getWriteFlg === 0;
  }

  public onClickEdit() {
    this.editFocused = !this.editFocused;
    this.editButtonLabel = this.editFocused
      ? editButtonLabelEditing
      : editButtonLabelBefore;

    if (!this.editFocused) {
      this.registerDashBoard();
    }
  }

  async created() {
    await this.initItems();
  }

  async beforeDestroy() {
    await DashBoardGet.clearResponse();
  }

  private componentNameFromMenuId(menuId: number) {
    let component = components.find(c => c.menuId == menuId);
    if (component == undefined) {
      component = components[0];
    }
    return component.name;
  }

  async initItems() {
    this.isLoadedItems = false;

    await DashBoardGet.get({ mode: 0 });
    if (!DashBoardGet.isSuccess) {
      await Flash.setErrorNow({
        message: DashBoardGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.activeItems = _.cloneDeep(DashBoardGet.getItems);
    this.activeItems.map((i, j) => {
      i.component = this.componentNameFromMenuId(i.menuId);
    });

    await DashBoardGet.get({ mode: 1 });
    if (!DashBoardGet.isSuccess) {
      await Flash.setErrorNow({
        message: DashBoardGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.inactiveItems = _.cloneDeep(DashBoardGet.getItems);
    this.inactiveItems.map((i, j) => {
      if (i.viewFg == 1) {
        this.inactiveItems.splice(j,1);
        return;
      }
      i.component = this.componentNameFromMenuId(i.menuId);
    });

    this.isLoadedItems = true;
  }

  async registerDashBoard() {
    this.isLoadedItems = false;
    const sort: number[] = [];
    this.activeItems.forEach((i, index) => {
      sort[index] = index + 1;
    });

    const ids = this.activeItems.map(i => {
      return i.id;
    });

    const menuIds = this.activeItems.map(i => {
      return i.menuId;
    });

    const request: RegisterRequest = {
      sort: sort,
      id: ids,
      menuId: menuIds
    };

    await DashBoardRegister.register(request);
    if (!DashBoardRegister.isSuccess) {
      await Flash.setErrorNow({
        message: DashBoardRegister.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }

    await this.initItems();
  }
}
