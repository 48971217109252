import service from "@/api/service";
import { PointOriginalListGetRequest } from "@/api/analysis/point-original-list/request";
import { DownloadCSVResponse } from "@/api/response";

/**
 * 店舗ポイントカード利用リストCSV出力APIをコールします。
 *
 * @param getRequest 店舗ポイントカード利用リストCSV出力APIのリクエストボディ
 * @return response
 */
export async function get(getRequest: PointOriginalListGetRequest) {
  const response = await service.postReceiveCSV(
    "/analysis-point-original-list-csv",
    getRequest
  );
  return response as DownloadCSVResponse;
}
